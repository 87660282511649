import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentMatchmakingStats } from '../services/api';

// Async thunk for fetching matchmaking stats
export const fetchMatchmakingStats = createAsyncThunk(
  'matchmaking/fetchStats',
  async () => {
    const stats = await getCurrentMatchmakingStats();
    return stats;
  }
);

const matchmakingSlice = createSlice({
  name: 'matchmaking',
  initialState: {
    inQueue: 0,
    inMatch: 0,
    totalPlayers: 0,
    loading: false,
    error: null,
    lastUpdated: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMatchmakingStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMatchmakingStats.fulfilled, (state, action) => {
        state.loading = false;
        state.inQueue = action.payload.inQueue;
        state.inMatch = action.payload.inMatch;
        state.totalPlayers = action.payload.totalPlayers;
        state.lastUpdated = new Date().toISOString();
      })
      .addCase(fetchMatchmakingStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default matchmakingSlice.reducer;
