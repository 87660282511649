import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from '../services/api';
import PlayerSearchDropdown from './PlayerSearchDropdown';

const STAT_CATEGORIES = {
  overview: {
    name: 'OVERVIEW',
    stats: [
      { id: 'winRate', name: 'WIN RATE', format: (val) => `${val.toFixed(1)}%` },
      { id: 'totalGames', name: 'GAMES PLAYED', format: (val) => val },
      { id: 'totalWins', name: 'TOTAL WINS', format: (val) => val },
      { id: 'totalLosses', name: 'TOTAL LOSSES', format: (val) => val }
    ]
  },
  scoring: {
    name: 'SCORING',
    stats: [
      { id: 'goals', name: 'GOALS', format: (val) => val },
      { id: 'goalsPerGame', name: 'GOALS/GAME', format: (val, player) => (player.gameStats.goals / (player.totalGames || 1)).toFixed(2) },
      { id: 'gameWinningGoals', name: 'GWG', format: (val) => val },
      { id: 'overtimeGoals', name: 'OT GOALS', format: (val) => val }
    ]
  },
  playmaking: {
    name: 'PLAYMAKING',
    stats: [
      { id: 'assists', name: 'ASSISTS', format: (val) => val },
      { id: 'primaryAssists', name: 'PRIMARY', format: (val) => val },
      { id: 'secondaryAssists', name: 'SECONDARY', format: (val) => val },
      { id: 'passes', name: 'PASSES', format: (val) => val }
    ]
  },
  defense: {
    name: 'DEFENSE',
    stats: [
      { id: 'saves', name: 'SAVES', format: (val) => val },
      { id: 'blocks', name: 'BLOCKS', format: (val) => val },
      { id: 'takeaways', name: 'TAKEAWAYS', format: (val) => val },
      { id: 'turnovers', name: 'TURNOVERS', format: (val) => val }
    ]
  },
  faceoffs: {
    name: 'FACEOFFS',
    stats: [
      { id: 'faceoffsWon', name: 'FACEOFFS WON', format: (val) => val },
      { id: 'faceoffsLost', name: 'FACEOFFS LOST', format: (val) => val },
      { id: 'faceoffWinRate', name: 'FACEOFF WIN %', format: (val, player) => {
        const total = player.gameStats.faceoffsWon + player.gameStats.faceoffsLost;
        return total > 0 ? `${((player.gameStats.faceoffsWon / total) * 100).toFixed(1)}%` : '0%';
      }},
      { id: 'totalFaceoffs', name: 'TOTAL FACEOFFS', format: (val, player) => player.gameStats.faceoffsWon + player.gameStats.faceoffsLost }
    ]
  },
  misc: {
    name: 'MISC',
    stats: [
      { id: 'shutouts', name: 'SHUTOUTS', format: (val) => val },
      { id: 'shutoutsAgainst', name: 'SHUTOUTS AGAINST', format: (val) => val },
      { id: 'postHits', name: 'POST HITS', format: (val) => val },
      { id: 'avgPossessionTime', name: 'AVG POSSESSION', format: (val, player) => {
        const avgSeconds = player.totalGames > 0 ? Math.round(player.gameStats.possessionTimeSec / player.totalGames) : 0;
        return `${Math.floor(avgSeconds / 60)}:${(avgSeconds % 60).toString().padStart(2, '0')}`;
      }}
    ]
  }
};

const PlayerCard = ({ player, loading }) => {
  if (loading) {
    return (
      <div className="h-16 bg-deep-navy/95 rounded-lg border border-arena-glow/20 animate-pulse"></div>
    );
  }

  if (!player) return null;

  return (
    <div className="flex items-center gap-3 p-4 bg-deep-navy/95 rounded-lg border border-arena-glow/20 hover:border-arena-glow/40 transition-all duration-300 group hover:shadow-[0_0_15px_rgba(77,171,245,0.2)]">
      <div className="relative">
        {player.avatarUrl && (
          <img 
            src={player.avatarUrl} 
            alt={player.username} 
            className="w-10 h-10 rounded-full border-2 border-arena-glow/20 group-hover:border-arena-glow/40 transition-all duration-300"
          />
        )}
        {player.isPremium && (
          <div className="absolute -top-1 -right-1 w-4 h-4 bg-goal-gold/20 rounded-full border border-goal-gold/40 flex items-center justify-center">
            <span className="text-[8px] text-goal-gold">★</span>
          </div>
        )}
      </div>
      <div className="flex-1">
        <div className="flex items-center gap-2">
          <span className="text-ice-blue font-game group-hover:text-arena-glow transition-colors duration-300">
            {player.username}
          </span>
          {player.isPremium && (
            <span className="text-[10px] px-2 py-0.5 bg-goal-gold/20 text-goal-gold rounded-full border border-goal-gold/20 animate-pulse">
              PRO
            </span>
          )}
        </div>
        <div className="text-sm text-ice-blue/60">
          {player.totalGames} Games • {player.winRate.toFixed(1)}% Win Rate
        </div>
      </div>
    </div>
  );
};

export default function PlayerComparison() {
  const [searchParams] = useSearchParams();
  const [player1, setPlayer1] = useState(null);
  const [player2, setPlayer2] = useState(null);
  const [category, setCategory] = useState('overview');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const player1Id = searchParams.get('player1');
    if (player1Id) {
      fetchPlayerStats(player1Id, 1);
    }
  }, [searchParams]);

  const fetchPlayerStats = async (id, slot) => {
    try {
      setLoading(true);
      const response = await api.get(`/api/players/${id}`);
      if (slot === 1) {
        setPlayer1(response.data);
      } else {
        setPlayer2(response.data);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePlayerSelect = async (player, slot) => {
    await fetchPlayerStats(player.slapshotId, slot);
  };

  const getStatValue = (stat, player) => {
    if (!player) return null;
    if (['winRate', 'totalGames', 'totalWins', 'totalLosses'].includes(stat.id)) {
      return player[stat.id];
    }
    if (stat.id === 'faceoffWinRate' || stat.id === 'avgPossessionTime' || stat.id === 'goalsPerGame' || stat.id === 'totalFaceoffs') {
      return stat.format(null, player);
    }
    return player.gameStats[stat.id];
  };

  const getStatDifference = (stat, p1, p2) => {
    if (!p1 || !p2) return null;
    const val1 = getStatValue(stat, p1);
    const val2 = getStatValue(stat, p2);

    if (stat.id === 'faceoffWinRate') {
      const p1Total = p1.gameStats.faceoffsWon + p1.gameStats.faceoffsLost;
      const p2Total = p2.gameStats.faceoffsWon + p2.gameStats.faceoffsLost;
      return ((p1Total > 0 ? (p1.gameStats.faceoffsWon / p1Total) * 100 : 0) -
              (p2Total > 0 ? (p2.gameStats.faceoffsWon / p2Total) * 100 : 0));
    }
    
    if (stat.id === 'avgPossessionTime') {
      return (p1.totalGames > 0 ? p1.gameStats.possessionTimeSec / p1.totalGames : 0) -
             (p2.totalGames > 0 ? p2.gameStats.possessionTimeSec / p2.totalGames : 0);
    }
    
    if (stat.id === 'goalsPerGame') {
      return (p1.gameStats.goals / (p1.totalGames || 1)) -
             (p2.gameStats.goals / (p2.totalGames || 1));
    }

    return val1 - val2;
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="font-game text-4xl text-ice-blue mb-8 drop-shadow-[0_0_5px_rgba(77,171,245,0.4)] animate-subtle-pulse">
        PLAYER COMPARISON
      </h1>

      {/* Player Selection */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <div className="space-y-4">
          <h2 className="font-game text-xl text-ice-blue drop-shadow-[0_0_3px_rgba(77,171,245,0.3)]">PLAYER 1</h2>
          <div className="relative group">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-arena-glow/50 to-rink-blue/50 rounded-lg blur opacity-30 group-hover:opacity-50 transition duration-300"></div>
            <div className="relative">
              <PlayerSearchDropdown 
                onSelect={(p) => handlePlayerSelect(p, 1)} 
                placeholder="Search for first player..."
              />
            </div>
          </div>
          <PlayerCard player={player1} loading={loading} />
        </div>

        <div className="space-y-4">
          <h2 className="font-game text-xl text-ice-blue drop-shadow-[0_0_3px_rgba(77,171,245,0.3)]">PLAYER 2</h2>
          <div className="relative group">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-arena-glow/50 to-rink-blue/50 rounded-lg blur opacity-30 group-hover:opacity-50 transition duration-300"></div>
            <div className="relative">
              <PlayerSearchDropdown 
                onSelect={(p) => handlePlayerSelect(p, 2)} 
                placeholder="Search for second player..."
              />
            </div>
          </div>
          <PlayerCard player={player2} loading={loading} />
        </div>
      </div>

      {/* Category Selection */}
      <div className="flex gap-2 mb-8 overflow-x-auto pb-2 scrollbar-hide">
        {Object.entries(STAT_CATEGORIES).map(([key, cat]) => (
          <button
            key={key}
            onClick={() => setCategory(key)}
            className={`relative group px-6 py-2.5 rounded-lg transition-all duration-300 font-game text-sm whitespace-nowrap ${
              category === key
                ? 'bg-gradient-to-r from-rink-blue/80 to-arena-glow/80 text-ice-blue shadow-[0_0_15px_rgba(77,171,245,0.3)]'
                : 'bg-deep-navy/95 text-ice-blue/80 hover:text-ice-blue border border-arena-glow/20 hover:border-arena-glow/40 hover:shadow-[0_0_15px_rgba(77,171,245,0.2)]'
            }`}
          >
            <div className="relative flex items-center gap-2">
              {cat.name}
              {category === key && (
                <div className="absolute inset-0 bg-gradient-to-r from-arena-glow/20 to-rink-blue/20 opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-lg"></div>
              )}
            </div>
          </button>
        ))}
      </div>

      {/* Stats Comparison */}
      {player1 && player2 && (
        <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg overflow-hidden shadow-[0_0_20px_rgba(77,171,245,0.1)]">
          <div className="divide-y divide-arena-glow/10">
            {STAT_CATEGORIES[category].stats.map((stat, index) => {
              const diff = getStatDifference(stat, player1, player2);
              const p1Value = getStatValue(stat, player1);
              const p2Value = getStatValue(stat, player2);
              
              return (
                <div 
                  key={stat.id} 
                  className="grid grid-cols-3 p-4 hover:bg-rink-blue/5 transition-colors duration-300"
                  style={{ animationDelay: `${index * 100}ms` }}
                >
                  <div className="text-right group">
                    <span className={`font-game text-lg transition-all duration-300 ${
                      diff > 0 ? 'text-arena-glow drop-shadow-[0_0_5px_rgba(77,171,245,0.4)]' : 'text-ice-blue/80'
                    } group-hover:scale-110 inline-block`}>
                      {stat.format(p1Value, player1)}
                    </span>
                  </div>
                  <div className="text-center relative px-4">
                    <span className="text-ice-blue/60 font-game text-sm absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap">
                      {stat.name}
                    </span>
                    {diff !== 0 && (
                      <div className={`absolute inset-0 ${
                        diff > 0 ? 'bg-gradient-to-l' : 'bg-gradient-to-r'
                      } from-arena-glow/5 to-transparent opacity-50`}></div>
                    )}
                  </div>
                  <div className="text-left group">
                    <span className={`font-game text-lg transition-all duration-300 ${
                      diff < 0 ? 'text-arena-glow drop-shadow-[0_0_5px_rgba(77,171,245,0.4)]' : 'text-ice-blue/80'
                    } group-hover:scale-110 inline-block`}>
                      {stat.format(p2Value, player2)}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {error && (
        <div className="mt-6 p-4 bg-goal-red/10 border border-goal-red rounded-lg text-goal-red font-game achievement-item">
          <div className="flex items-center gap-2">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            {error}
          </div>
        </div>
      )}
    </div>
  );
}
