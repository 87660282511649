import { createSlice } from '@reduxjs/toolkit';

// Get initial state from localStorage
const getInitialState = () => {
  const token = localStorage.getItem('token');
  const storedUser = localStorage.getItem('user');
  
  return {
    user: storedUser ? JSON.parse(storedUser) : null,
    token: token || null,
    isAuthenticated: !!token && !!storedUser, // Only consider authenticated if we have both token and user
    hasInitialized: false // Track whether we've completed initial auth check
  };
};

const initialState = getInitialState();

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload: { user, token } }) => {
      state.user = user;
      state.token = token;
      state.isAuthenticated = !!token && !!user; // Only set authenticated if we have both
      state.hasInitialized = true; // Mark as initialized when we set credentials
      
      // Store in localStorage
      if (token) {
        localStorage.setItem('token', token);
      }
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
      }
    },
    updateUser: (state, { payload: user }) => {
      state.user = user;
      state.isAuthenticated = !!state.token && !!user;
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
      }
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      // Don't reset hasInitialized on logout
      
      // Clear localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    },
    markInitialized: (state) => {
      state.hasInitialized = true;
    }
  },
});

export const { setCredentials, updateUser, logout, markInitialized } = authSlice.actions;

// Selectors
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectUser = (state) => state.auth.user;
export const selectToken = (state) => state.auth.token;
export const selectHasInitialized = (state) => state.auth.hasInitialized;

export default authSlice.reducer;
