import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getPlayerStats, getRecentMatches } from '../services/api';
import api from '../services/api';

const StatCard = ({ title, children, className = '' }) => (
  <div className={`bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-6 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.1)] group ${className}`}>
    <h3 className="font-game text-xl text-ice-blue mb-6 drop-shadow-[0_0_3px_rgba(77,171,245,0.3)] group-hover:drop-shadow-[0_0_5px_rgba(77,171,245,0.4)] transition-all duration-300">
      {title}
    </h3>
    <div className="space-y-4">
      {children}
    </div>
  </div>
);

const StatItem = ({ label, value, color, rank }) => (
  <div className="flex justify-between items-center bg-rink-blue/5 p-3 rounded-md hover:bg-rink-blue/10 transition-all duration-300 group transform hover:scale-[1.02]">
    <span className="text-ice-blue/80 font-game text-sm group-hover:text-ice-blue transition-colors duration-300">
      {label}
    </span>
    <div className="flex items-center gap-2">
      <span className={`${color} font-game text-lg drop-shadow-[0_0_3px_rgba(77,171,245,0.3)] group-hover:drop-shadow-[0_0_5px_rgba(77,171,245,0.4)] transition-all duration-300`}>
        {value}
      </span>
      {rank && (
        <span className="text-xs px-2 py-0.5 bg-arena-glow/10 text-arena-glow rounded-full border border-arena-glow/20">
          #{rank}
        </span>
      )}
    </div>
  </div>
);

const MatchCard = ({ match }) => (
  <Link
    to={`/match/${match.game.gameId}`}
    className="flex items-center justify-between p-4 bg-rink-blue/5 rounded-lg hover:bg-rink-blue/10 transition-all duration-300 group transform hover:scale-[1.02] hover:shadow-[0_0_15px_rgba(77,171,245,0.1)]"
  >
    <div className="flex items-center gap-4">
      <div className="w-14 h-14 bg-rink-blue/20 rounded-full flex items-center justify-center border border-arena-glow/20 group-hover:border-arena-glow/40 transition-all duration-300">
        <span className="text-ice-blue text-sm font-game group-hover:text-arena-glow transition-colors duration-300">
          #{match.game.gameId.slice(-4)}
        </span>
      </div>
      <div>
        <p className="text-ice-blue font-game text-lg group-hover:text-arena-glow transition-colors duration-300">
          {match.game.team1Score} - {match.game.team2Score}
        </p>
        <div className="flex items-center gap-2 text-ice-blue/60 text-sm">
          <span>{new Date(match.game.playedAt).toLocaleDateString()}</span>
          {match.game.endReason === 'Overtime' && (
            <span className="text-xs px-2 py-0.5 bg-goal-gold/20 text-goal-gold rounded-full border border-goal-gold/20 animate-pulse">
              OT
            </span>
          )}
        </div>
      </div>
    </div>
    <div className="text-right">
      <p className={`font-game text-lg ${match.won ? 'text-arena-glow' : 'text-goal-red'} ${match.won ? 'group-hover:drop-shadow-[0_0_5px_rgba(77,171,245,0.4)]' : 'group-hover:drop-shadow-[0_0_5px_rgba(255,71,87,0.4)]'} transition-all duration-300`}>
        {match.won ? 'VICTORY' : 'DEFEAT'}
      </p>
      <p className="text-ice-blue/60 text-sm font-game group-hover:text-ice-blue transition-colors duration-300">
        {match.score} PTS
      </p>
    </div>
  </Link>
);

export default function PlayerProfile() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [player, setPlayer] = useState(null);
  const [matches, setMatches] = useState([]);
  const [rankings, setRankings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        setLoading(true);
        const [playerData, matchesData, rankingsData] = await Promise.all([
          getPlayerStats(id),
          getRecentMatches(id),
          api.get(`/api/stats/player/${id}/rank`)
        ]);
        
        setPlayer(playerData);
        setMatches(matchesData.games);
        setRankings(rankingsData.data.rankings);
        setHasMore(matchesData.pagination.page < matchesData.pagination.pages);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayerData();
  }, [id]);

  const getSteamProfileUrl = (steamId) => {
    return `https://steamcommunity.com/profiles/${steamId}`;
  };

  const loadMoreMatches = async () => {
    try {
      const nextPage = page + 1;
      const matchesData = await getRecentMatches(id, nextPage);
      setMatches(prev => [...prev, ...matchesData.games]);
      setPage(nextPage);
      setHasMore(matchesData.pagination.page < matchesData.pagination.pages);
    } catch (err) {
      console.error('Failed to load more matches:', err);
    }
  };

  const handleCompare = () => {
    navigate(`/compare?player1=${id}`);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center gap-4">
          <div className="w-16 h-16 border-4 border-arena-glow/20 border-t-arena-glow rounded-full animate-spin"></div>
          <div className="text-ice-blue font-game animate-pulse">Loading player profile...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-goal-red/10 border border-goal-red rounded-lg p-6 text-goal-red font-game flex items-center gap-3">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          {error}
        </div>
      </div>
    );
  }

  if (!player) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Player Header */}
      <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-8 mb-8 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.1)]">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-6">
            <div className="relative group">
              <div className="absolute -inset-1 bg-gradient-to-r from-arena-glow to-rink-blue rounded-full blur opacity-25 group-hover:opacity-50 transition duration-300"></div>
              <a 
                href={getSteamProfileUrl(player?.steamId)} 
                target="_blank" 
                rel="noopener noreferrer"
                className="relative block w-24 h-24 rounded-full overflow-hidden border-2 border-arena-glow/20 group-hover:border-arena-glow/40 transition-all duration-300"
              >
                <img
                  src={player?.avatarUrl}
                  alt={player?.username}
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-300"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(player?.username || 'U')}&background=0D2B45&color=7AB5E1&size=128`;
                  }}
                />
                <div className="absolute inset-0 bg-deep-navy/0 group-hover:bg-deep-navy/20 transition-all duration-300" />
              </a>
            </div>
            <div>
              <div className="flex items-center gap-3">
                <h1 className="text-4xl font-game text-ice-blue drop-shadow-[0_0_5px_rgba(77,171,245,0.4)] animate-subtle-pulse">
                  {player.username}
                </h1>
                {player.isPremium && (
                  <span className="text-xs px-3 py-1 bg-goal-gold/20 text-goal-gold rounded-full border border-goal-gold/20 animate-pulse flex items-center gap-1">
                    <span className="text-[10px]">★</span>
                    PREMIUM
                  </span>
                )}
              </div>
              <div className="flex gap-4 text-ice-blue/80 font-game text-sm mt-2">
                <span>ID: {player.id}</span>
                <span>•</span>
                <span className="text-arena-glow">WIN RATE: {player.winRate.toFixed(1)}%</span>
              </div>
            </div>
          </div>
          <button
            onClick={handleCompare}
            className="relative group px-6 py-3 bg-gradient-to-r from-rink-blue/80 to-arena-glow/80 text-ice-blue rounded-lg transition-all duration-300 font-game hover:shadow-[0_0_15px_rgba(77,171,245,0.3)] transform hover:scale-105"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-arena-glow/20 to-rink-blue/20 opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-lg"></div>
            <span className="relative">COMPARE STATS</span>
          </button>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        {/* Career Stats */}
        <StatCard title="CAREER STATS">
          <StatItem label="TOTAL GAMES" value={player.totalGames} color="text-ice-blue" />
          <StatItem label="WIN RATE" value={`${player.winRate.toFixed(1)}%`} color="text-arena-glow" />
          <StatItem label="GOALS" value={player.gameStats.goals} color="text-goal-red" />
          <StatItem label="ASSISTS" value={player.gameStats.assists} color="text-arena-glow" />
          <StatItem label="SAVES" value={player.gameStats.saves} color="text-ice-blue" />
          <StatItem label="SHOTS" value={player.gameStats.shots} color="text-ice-blue" />
          <StatItem label="PASSES" value={player.gameStats.passes} color="text-ice-blue" />
        </StatCard>

        {/* Rankings */}
        <StatCard title="RANKINGS">
          {rankings && (
            <>
              <StatItem label="WIN RATE" value={`${player.winRate.toFixed(1)}%`} color="text-arena-glow" rank={rankings.winrate} />
              <StatItem label="TOTAL GAMES" value={player.totalGames} color="text-ice-blue" rank={rankings.totalGames} />
              <StatItem label="TOTAL WINS" value={player.totalWins} color="text-arena-glow" rank={rankings.totalWins} />
              <StatItem label="GOALS" value={player.gameStats.goals} color="text-goal-red" rank={rankings.totalGoals} />
              <StatItem label="ASSISTS" value={player.gameStats.assists} color="text-arena-glow" rank={rankings.totalAssists} />
              <StatItem label="SAVES" value={player.gameStats.saves} color="text-ice-blue" rank={rankings.totalSaves} />
            </>
          )}
        </StatCard>

        {/* Recent Performance */}
        <StatCard title="RECENT PERFORMANCE">
          {matches.slice(0, 1).map(match => (
            <div key={match.game.gameId} className="space-y-3">
              <StatItem 
                label="LAST MATCH" 
                value={match.won ? 'VICTORY' : 'DEFEAT'} 
                color={match.won ? 'text-arena-glow' : 'text-goal-red'} 
              />
              <StatItem label="SCORE" value={match.score} color="text-ice-blue" />
              <StatItem label="GOALS" value={match.goals} color="text-goal-red" />
              <StatItem label="ASSISTS" value={match.assists} color="text-arena-glow" />
              <StatItem label="SAVES" value={match.saves} color="text-ice-blue" />
            </div>
          ))}
        </StatCard>
      </div>

      {/* Match History */}
      <StatCard title="MATCH HISTORY" className="achievement-item">
        <div className="space-y-4">
          {matches.map((match, index) => (
            <div key={match.game.gameId} style={{ animationDelay: `${index * 50}ms` }}>
              <MatchCard match={match} />
            </div>
          ))}
        </div>
        
        {hasMore && (
          <button
            onClick={loadMoreMatches}
            className="w-full mt-6 py-3 bg-deep-navy/95 text-ice-blue rounded-lg border border-arena-glow/20 hover:border-arena-glow/40 transition-all duration-300 font-game hover:shadow-[0_0_15px_rgba(77,171,245,0.2)] relative group overflow-hidden"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-arena-glow/20 to-rink-blue/20 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
            <span className="relative flex items-center justify-center gap-2">
              LOAD MORE MATCHES
              <svg className="w-4 h-4 transform group-hover:translate-y-1 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
              </svg>
            </span>
          </button>
        )}
      </StatCard>
    </div>
  );
}
