import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';

const TIMEFRAMES = [
  { id: 'all', name: 'ALL TIME' },
  { id: 'season', name: 'THIS SEASON' },
  { id: 'month', name: 'THIS MONTH' },
  { id: 'week', name: 'THIS WEEK' },
  { id: 'day', name: 'TODAY' }
];

const STAT_CATEGORIES = {
  overview: {
    name: 'OVERVIEW',
    stats: ['winrate', 'goals', 'assists', 'points']
  },
  scoring: {
    name: 'SCORING',
    stats: ['goals_per_game', 'shooting_pct', 'gwg', 'otg']
  },
  playmaking: {
    name: 'PLAYMAKING',
    stats: ['assists_per_game', 'primary_assists_pct', 'passes', 'possession']
  },
  defense: {
    name: 'DEFENSE',
    stats: ['saves', 'blocks', 'takeaways', 'turnovers']
  },
  faceoffs: {
    name: 'FACEOFFS',
    stats: ['faceoff_pct', 'faceoffs_per_game']
  }
};

const RankBadge = ({ rank }) => {
  let badgeClass = "w-8 h-8 rounded-full flex items-center justify-center border font-game text-sm";
  
  if (rank === 1) {
    badgeClass += " bg-goal-gold/20 border-goal-gold text-goal-gold animate-pulse";
  } else if (rank === 2) {
    badgeClass += " bg-ice-blue/20 border-ice-blue text-ice-blue";
  } else if (rank === 3) {
    badgeClass += " bg-goal-red/20 border-goal-red text-goal-red";
  } else {
    badgeClass += " bg-rink-blue/10 border-arena-glow/20 text-ice-blue/80";
  }

  return (
    <div className={badgeClass}>
      #{rank}
    </div>
  );
};

export default function Leaderboard() {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeframe, setTimeframe] = useState('all');
  const [category, setCategory] = useState('overview');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchLeaderboard();
  }, [timeframe, category]);

  const fetchLeaderboard = async (reset = true) => {
    try {
      setLoading(true);
      const currentPage = reset ? 1 : page;
      const response = await api.get('/api/stats/leaderboard', {
        params: {
          timeframe,
          page: currentPage,
          limit: 50
        }
      });

      setPlayers(prev => reset ? response.data.players : [...prev, ...response.data.players]);
      setHasMore(response.data.pagination.page < response.data.pagination.pages);
      if (!reset) {
        setPage(currentPage + 1);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (!loading && hasMore) {
      setPage(prev => prev + 1);
      fetchLeaderboard(false);
    }
  };

  const calculateStats = (player) => {
    const gamesPlayed = player.totalGames || 1;
    const totalPoints = player.totalGoals + player.totalAssists;
    const totalFaceoffs = player.totalFaceoffsWon + player.totalFaceoffsLost;
    
    return {
      winrate: `${player.winRate.toFixed(1)}%`,
      goals: player.totalGoals,
      assists: player.totalAssists,
      points: totalPoints,
      goals_per_game: (player.totalGoals / gamesPlayed).toFixed(2),
      shooting_pct: player.totalShots > 0 ? `${((player.totalGoals / player.totalShots) * 100).toFixed(1)}%` : '0%',
      gwg: player.totalGameWinningGoals,
      otg: player.totalOvertimeGoals,
      assists_per_game: (player.totalAssists / gamesPlayed).toFixed(2),
      primary_assists_pct: player.totalAssists > 0 ? 
        `${((player.totalPrimaryAssists / player.totalAssists) * 100).toFixed(1)}%` : '0%',
      passes: player.totalPasses,
      possession: `${Math.floor(player.totalPossessionTimeSec / gamesPlayed)}s`,
      saves: player.totalSaves,
      blocks: player.totalBlocks,
      takeaways: player.totalTakeaways,
      turnovers: player.totalTurnovers,
      faceoff_pct: totalFaceoffs > 0 ? 
        `${((player.totalFaceoffsWon / totalFaceoffs) * 100).toFixed(1)}%` : '0%',
      faceoffs_per_game: (totalFaceoffs / gamesPlayed).toFixed(1)
    };
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Header Section */}
      <div className="mb-8">
        <h1 className="font-game text-4xl text-ice-blue mb-4 drop-shadow-[0_0_5px_rgba(77,171,245,0.4)] animate-subtle-pulse">
          LEADERBOARD
        </h1>
        <div className="flex flex-wrap gap-4">
          {/* Timeframe Filter */}
          <div className="relative group">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-arena-glow/50 to-rink-blue/50 rounded-lg blur opacity-30 group-hover:opacity-50 transition duration-300"></div>
            <select 
              value={timeframe}
              onChange={(e) => setTimeframe(e.target.value)}
              className="relative bg-deep-navy/95 text-ice-blue font-game text-sm px-4 py-2 rounded-lg border border-arena-glow/20 hover:border-arena-glow/40 focus:border-arena-glow focus:outline-none transition-all duration-300 cursor-pointer appearance-none pr-10 hover:shadow-[0_0_15px_rgba(77,171,245,0.2)]"
            >
              {TIMEFRAMES.map(tf => (
                <option key={tf.id} value={tf.id}>{tf.name}</option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-arena-glow">
              <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </div>
          </div>

          {/* Category Filter */}
          <div className="relative group">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-arena-glow/50 to-rink-blue/50 rounded-lg blur opacity-30 group-hover:opacity-50 transition duration-300"></div>
            <select 
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="relative bg-deep-navy/95 text-ice-blue font-game text-sm px-4 py-2 rounded-lg border border-arena-glow/20 hover:border-arena-glow/40 focus:border-arena-glow focus:outline-none transition-all duration-300 cursor-pointer appearance-none pr-10 hover:shadow-[0_0_15px_rgba(77,171,245,0.2)]"
            >
              {Object.entries(STAT_CATEGORIES).map(([key, cat]) => (
                <option key={key} value={key}>{cat.name}</option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-arena-glow">
              <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      {/* Leaderboard Table */}
      <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg overflow-hidden shadow-[0_0_20px_rgba(77,171,245,0.1)]">
        {/* Table Header */}
        <div className="grid grid-cols-[80px_minmax(150px,1fr)_repeat(4,minmax(80px,1fr))] gap-4 p-4 border-b border-arena-glow/20 bg-gradient-to-r from-rink-blue/10 to-transparent">
          <div className="text-ice-blue font-game text-sm">RANK</div>
          <div className="text-ice-blue font-game text-sm">PLAYER</div>
          {STAT_CATEGORIES[category].stats.map(stat => (
            <div key={stat} className="text-ice-blue font-game text-sm text-right">
              {stat.toUpperCase().replace(/_/g, ' ')}
            </div>
          ))}
        </div>

        {/* Table Body */}
        <div className="divide-y divide-arena-glow/10">
          {players.map((player, index) => {
            const stats = calculateStats(player);
            return (
              <Link
                key={player.slapshotId}
                to={`/player/${player.slapshotId}`}
                className="grid grid-cols-[80px_minmax(150px,1fr)_repeat(4,minmax(80px,1fr))] gap-4 p-4 hover:bg-gradient-to-r hover:from-rink-blue/10 hover:to-transparent transition-all duration-300 group relative achievement-item"
                style={{ animationDelay: `${index * 50}ms` }}
              >
                <div className="flex items-center">
                  <RankBadge rank={player.rank} />
                </div>
                <div className="flex items-center gap-3 min-w-0">
                  {player.avatarUrl && (
                    <img 
                      src={player.avatarUrl} 
                      alt={player.username} 
                      className="w-8 h-8 rounded-full border border-arena-glow/20 group-hover:border-arena-glow/40 transition-all duration-300 flex-shrink-0"
                    />
                  )}
                  <div className="flex items-center gap-2 min-w-0">
                    <span className="text-ice-blue font-game group-hover:text-arena-glow transition-colors duration-300 truncate">
                      {player.username}
                    </span>
                    {player.isPremium && (
                      <span className="flex-shrink-0 text-[10px] px-2 py-0.5 bg-goal-gold/20 text-goal-gold rounded-full border border-goal-gold/20 animate-pulse">
                        PRO
                      </span>
                    )}
                  </div>
                </div>
                {STAT_CATEGORIES[category].stats.map(stat => (
                  <div key={stat} className="text-right group-hover:transform group-hover:scale-105 transition-transform duration-300">
                    <span className="text-arena-glow font-game text-sm drop-shadow-[0_0_2px_rgba(77,171,245,0.3)]">
                      {stats[stat]}
                    </span>
                  </div>
                ))}
              </Link>
            );
          })}
        </div>
      </div>

      {/* Load More Button */}
      {hasMore && (
        <div className="mt-6 flex justify-center">
          <button
            onClick={loadMore}
            disabled={loading}
            className="relative group px-8 py-3 bg-deep-navy/95 text-ice-blue rounded-lg border border-arena-glow/20 hover:border-arena-glow/40 transition-all duration-300 font-game disabled:opacity-50 overflow-hidden"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-arena-glow/20 to-rink-blue/20 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
            <div className="relative flex items-center gap-2">
              {loading ? (
                <>
                  <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <span className="animate-pulse">LOADING...</span>
                </>
              ) : (
                <>
                  <span>LOAD MORE</span>
                  <svg className="w-4 h-4 transform group-hover:translate-y-1 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                  </svg>
                </>
              )}
            </div>
          </button>
        </div>
      )}

      {/* Error Message */}
      {error && (
        <div className="mt-6 p-4 bg-goal-red/10 border border-goal-red rounded-lg text-goal-red font-game achievement-item">
          <div className="flex items-center gap-2">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            {error}
          </div>
        </div>
      )}
    </div>
  );
}
