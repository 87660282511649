import React, { useState, useCallback, useRef } from 'react';
import api from '../services/api';

const ImportGames = () => {
    const [files, setFiles] = useState([]);
    const [importing, setImporting] = useState(false);
    const [progress, setProgress] = useState(null);
    const [error, setError] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const dragCounter = useRef(0);

    const checkProgress = useCallback(async (importId) => {
        try {
            const response = await api.get(`/api/import/progress/${importId}`);
            setProgress(response.data);
            
            if (response.data.current < response.data.total) {
                setTimeout(() => checkProgress(importId), 1000);
            } else {
                setImporting(false);
            }
        } catch (error) {
            console.error('Error checking progress:', error);
            setError('Failed to check import progress');
            setImporting(false);
        }
    }, []);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current++;
        if (dragCounter.current === 1) {
            setIsDragging(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current--;
        if (dragCounter.current === 0) {
            setIsDragging(false);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        dragCounter.current = 0;
        
        const droppedFiles = Array.from(e.dataTransfer.files).filter(
            file => file.name.endsWith('.json')
        );
        
        if (droppedFiles.length === 0) {
            setError('Please drop only JSON files');
            return;
        }
        
        setFiles(droppedFiles);
        setError(null);
    };

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files).filter(
            file => file.name.endsWith('.json')
        );
        
        if (selectedFiles.length === 0) {
            setError('Please select only JSON files');
            return;
        }
        
        setFiles(selectedFiles);
        setError(null);
    };

    const handleImport = async () => {
        if (files.length === 0) {
            setError('Please select files to import');
            return;
        }

        setImporting(true);
        setError(null);
        setProgress(null);

        try {
            const fileContents = await Promise.all(
                files.map(async (file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = (e) => resolve({
                            name: file.name,
                            content: e.target.result
                        });
                        reader.onerror = reject;
                        reader.readAsText(file);
                    });
                })
            );

            const response = await api.post('/api/import/games', {
                files: fileContents
            });

            checkProgress(response.data.importId);
        } catch (error) {
            console.error('Import failed:', error);
            setError('Failed to start import');
            setImporting(false);
        }
    };

    return (
        <div className="min-h-screen bg-deep-navy p-8">
            <div className="max-w-4xl mx-auto">
                <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-8 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.1)]">
                    <h2 className="text-4xl font-game text-ice-blue mb-8 drop-shadow-[0_0_5px_rgba(77,171,245,0.4)] animate-subtle-pulse">
                        IMPORT GAMES
                    </h2>
                    
                    <div className="mb-8">
                        <div 
                            className={`relative border-2 border-dashed rounded-lg p-12 text-center cursor-pointer transition-all duration-300 group ${
                                isDragging 
                                    ? 'border-arena-glow bg-arena-glow/10 scale-[1.02]' 
                                    : 'border-arena-glow/30 hover:border-arena-glow hover:bg-arena-glow/5 hover:scale-[1.01]'
                            }`}
                            onClick={() => fileInputRef.current?.click()}
                            onDragEnter={handleDragEnter}
                            onDragLeave={handleDragLeave}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                        >
                            <div className="absolute inset-0 bg-gradient-to-r from-arena-glow/5 to-rink-blue/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-lg"></div>
                            <input
                                ref={fileInputRef}
                                type="file"
                                multiple
                                accept=".json"
                                onChange={handleFileChange}
                                className="hidden"
                                disabled={importing}
                            />
                            <div className="text-ice-blue/80 relative">
                                {files.length > 0 
                                    ? (
                                        <div className="space-y-4">
                                            <div className="text-2xl font-game text-arena-glow drop-shadow-[0_0_3px_rgba(77,171,245,0.3)]">
                                                {files.length} file{files.length === 1 ? '' : 's'} selected
                                            </div>
                                            <div className="text-sm text-ice-blue/60">
                                                Click or drag to change selection
                                            </div>
                                            <div className="mt-4 space-y-2">
                                                {files.map((file, index) => (
                                                    <div 
                                                        key={index}
                                                        className="text-sm text-ice-blue/80 bg-rink-blue/5 py-2 px-4 rounded-md inline-block mx-1 border border-arena-glow/20"
                                                    >
                                                        {file.name}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="space-y-4">
                                            <svg className="w-16 h-16 mx-auto text-arena-glow/50 group-hover:text-arena-glow/70 transition-colors duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                                            </svg>
                                            <div className="text-2xl font-game text-ice-blue group-hover:text-arena-glow transition-colors duration-300">
                                                DROP JSON FILES HERE
                                            </div>
                                            <div className="text-sm text-ice-blue/60">
                                                or click to select files
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        <div className="mt-6 flex justify-end">
                            <button
                                onClick={handleImport}
                                disabled={importing || files.length === 0}
                                className={`relative group px-6 py-3 rounded-lg font-game text-white transition-all duration-300 ${
                                    importing || files.length === 0
                                        ? 'bg-deep-navy/50 text-ice-blue/50 cursor-not-allowed'
                                        : 'bg-gradient-to-r from-rink-blue/80 to-arena-glow/80 hover:shadow-[0_0_15px_rgba(77,171,245,0.3)] transform hover:scale-105'
                                }`}
                            >
                                <div className="absolute inset-0 bg-gradient-to-r from-arena-glow/20 to-rink-blue/20 opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-lg"></div>
                                <span className="relative flex items-center gap-2">
                                    {importing ? (
                                        <>
                                            <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            <span className="animate-pulse">IMPORTING...</span>
                                        </>
                                    ) : (
                                        <>
                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                                            </svg>
                                            IMPORT FILES
                                        </>
                                    )}
                                </span>
                            </button>
                        </div>
                    </div>

                    {error && (
                        <div className="mb-8 p-4 bg-goal-red/10 border border-goal-red rounded-lg text-goal-red font-game flex items-center gap-3 achievement-item">
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            {error}
                        </div>
                    )}

                    {progress && (
                        <div className="mb-8 p-6 bg-deep-navy/95 border border-arena-glow/20 rounded-lg hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_15px_rgba(77,171,245,0.1)] achievement-item">
                            <div className="flex justify-between mb-4 text-sm font-game">
                                <span className="text-ice-blue">
                                    Progress: {progress.processed} / {progress.total} files
                                    {progress.failed > 0 && (
                                        <span className="text-goal-red ml-2">
                                            ({progress.failed} failed)
                                        </span>
                                    )}
                                </span>
                                <span className="text-arena-glow">
                                    {Math.round((progress.current / progress.total) * 100)}%
                                </span>
                            </div>
                            <div className="relative w-full h-2 bg-deep-navy rounded-full overflow-hidden">
                                <div className="absolute inset-0 bg-gradient-to-r from-arena-glow/20 to-rink-blue/20 animate-pulse"></div>
                                <div
                                    className="relative h-full bg-gradient-to-r from-arena-glow to-rink-blue rounded-full transition-all duration-300"
                                    style={{
                                        width: `${(progress.current / progress.total) * 100}%`
                                    }}
                                />
                            </div>
                            {progress.errors.length > 0 && (
                                <div className="mt-6 space-y-3">
                                    <h3 className="font-game text-ice-blue">ERRORS:</h3>
                                    <div className="bg-goal-red/10 rounded-lg p-4 border border-goal-red/20">
                                        <ul className="space-y-2">
                                            {progress.errors.map((error, index) => (
                                                <li 
                                                    key={index} 
                                                    className="text-goal-red text-sm flex items-center gap-2"
                                                    style={{ animationDelay: `${index * 100}ms` }}
                                                >
                                                    <svg className="w-4 h-4 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                    {error}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ImportGames;
