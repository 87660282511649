import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import matchmakingReducer from './matchmakingSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    matchmaking: matchmakingReducer
  }
});
