import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import { getRecentMatches, syncSelf } from '../services/api';

const StatCard = ({ title, children, className = '' }) => (
  <div className={`bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-6 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.1)] group ${className}`}>
    <h3 className="font-game text-xl text-ice-blue mb-6 drop-shadow-[0_0_3px_rgba(77,171,245,0.3)] group-hover:drop-shadow-[0_0_5px_rgba(77,171,245,0.4)] transition-all duration-300">
      {title}
    </h3>
    <div className="space-y-4">
      {children}
    </div>
  </div>
);

const StatItem = ({ label, value, color }) => (
  <div className="flex justify-between items-center bg-rink-blue/5 p-3 rounded-md hover:bg-rink-blue/10 transition-all duration-300 group transform hover:scale-[1.02]">
    <span className="text-ice-blue/80 font-game text-sm group-hover:text-ice-blue transition-colors duration-300">
      {label}
    </span>
    <span className={`${color} font-game text-lg drop-shadow-[0_0_3px_rgba(77,171,245,0.3)] group-hover:drop-shadow-[0_0_5px_rgba(77,171,245,0.4)] transition-all duration-300`}>
      {value}
    </span>
  </div>
);

const LoadoutItem = ({ name, value, isColor }) => (
  <div className="flex justify-between items-center bg-rink-blue/5 p-3 rounded-md hover:bg-rink-blue/10 transition-all duration-300 group transform hover:scale-[1.02]">
    <span className="text-ice-blue/80 font-game text-sm group-hover:text-ice-blue transition-colors duration-300">
      {name}
    </span>
    <div className="flex items-center gap-2">
      <span className="text-ice-blue font-game text-sm group-hover:text-arena-glow transition-colors duration-300">
        {value.cosmetic.replace(/^default_/, '').replace(/^[^_]+_/, '')}
      </span>
      {isColor && (
        <div 
          className="w-6 h-6 rounded-full border border-arena-glow/20 group-hover:border-arena-glow/40 transition-all duration-300"
          style={{ backgroundColor: `#${value.cosmetic.split('_').pop()}` }}
        />
      )}
    </div>
  </div>
);

const MatchCard = ({ match }) => (
  <Link
    to={`/match/${match.game.gameId}`}
    className="flex items-center justify-between p-4 bg-rink-blue/5 rounded-lg hover:bg-rink-blue/10 transition-all duration-300 group transform hover:scale-[1.02] hover:shadow-[0_0_15px_rgba(77,171,245,0.1)]"
  >
    <div className="flex items-center gap-4">
      <div className="w-14 h-14 bg-rink-blue/20 rounded-full flex items-center justify-center border border-arena-glow/20 group-hover:border-arena-glow/40 transition-all duration-300">
        <span className="text-ice-blue text-sm font-game group-hover:text-arena-glow transition-colors duration-300">
          #{match.game.gameId.slice(-4)}
        </span>
      </div>
      <div>
        <p className="text-ice-blue font-game text-lg group-hover:text-arena-glow transition-colors duration-300">
          {match.game.team1Score} - {match.game.team2Score}
        </p>
        <div className="flex items-center gap-2 text-ice-blue/60 text-sm">
          <span>{new Date(match.game.playedAt).toLocaleDateString()}</span>
          {match.game.endReason === 'Overtime' && (
            <span className="text-xs px-2 py-0.5 bg-goal-gold/20 text-goal-gold rounded-full border border-goal-gold/20 animate-pulse">
              OT
            </span>
          )}
        </div>
      </div>
    </div>
    <div className="text-right">
      <p className={`font-game text-lg ${match.won ? 'text-arena-glow' : 'text-goal-red'} ${match.won ? 'group-hover:drop-shadow-[0_0_5px_rgba(77,171,245,0.4)]' : 'group-hover:drop-shadow-[0_0_5px_rgba(255,71,87,0.4)]'} transition-all duration-300`}>
        {match.won ? 'VICTORY' : 'DEFEAT'}
      </p>
      <p className="text-ice-blue/60 text-sm font-game group-hover:text-ice-blue transition-colors duration-300">
        {match.score} PTS
      </p>
    </div>
  </Link>
);

export default function Profile() {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [syncing, setSyncing] = useState(false);
  const [syncError, setSyncError] = useState(null);

  useEffect(() => {
    if (user?.slapshotId) {
      fetchMatches();
    }
  }, [user?.slapshotId]);

  const fetchMatches = async () => {
    try {
      setLoading(true);
      const matchesData = await getRecentMatches(user.slapshotId);
      setMatches(matchesData.games);
      setHasMore(matchesData.pagination.page < matchesData.pagination.pages);
    } catch (err) {
      console.error('Failed to fetch matches:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleSync = async () => {
    try {
      setSyncing(true);
      setSyncError(null);
      const result = await syncSelf();
      if (result.success) {
        await fetchMatches();
      } else {
        setSyncError(result.error);
      }
    } catch (err) {
      console.error('Failed to sync:', err);
      setSyncError('Failed to sync. Please try again later.');
    } finally {
      setSyncing(false);
    }
  };

  const loadMoreMatches = async () => {
    try {
      const nextPage = page + 1;
      const matchesData = await getRecentMatches(user.slapshotId, nextPage);
      setMatches(prev => [...prev, ...matchesData.games]);
      setPage(nextPage);
      setHasMore(matchesData.pagination.page < matchesData.pagination.pages);
    } catch (err) {
      console.error('Failed to load more matches:', err);
    }
  };

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getSteamProfileUrl = (steamId) => {
    return `https://steamcommunity.com/profiles/${steamId}`;
  };

  const formatItemName = (name) => {
    return name
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (c) => c.toUpperCase())
      .replace(/color/i, 'Color');
  };

  const isColorItem = (key) => key.toLowerCase().includes('color');

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Profile Header */}
      <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-8 mb-8 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.1)]">
        <div className="flex items-center gap-6">
          <div className="relative group">
            <div className="absolute -inset-1 bg-gradient-to-r from-arena-glow to-rink-blue rounded-full blur opacity-25 group-hover:opacity-50 transition duration-300"></div>
            <a 
              href={getSteamProfileUrl(user?.steamId)} 
              target="_blank" 
              rel="noopener noreferrer"
              className="relative block w-24 h-24 rounded-full overflow-hidden border-2 border-arena-glow/20 group-hover:border-arena-glow/40 transition-all duration-300"
            >
              <img
                src={user?.avatarUrl}
                alt={user?.name}
                className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-300"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(user?.name || 'U')}&background=0D2B45&color=7AB5E1&size=128`;
                }}
              />
              <div className="absolute inset-0 bg-deep-navy/0 group-hover:bg-deep-navy/20 transition-all duration-300" />
            </a>
          </div>
          <div className="flex-grow">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <h1 className="text-4xl font-game text-ice-blue drop-shadow-[0_0_5px_rgba(77,171,245,0.4)] animate-subtle-pulse">
                  {user?.username}
                </h1>
                {user?.isPremium && (
                  <span className="text-xs px-3 py-1 bg-goal-gold/20 text-goal-gold rounded-full border border-goal-gold/20 animate-pulse flex items-center gap-1">
                    <span className="text-[10px]">★</span>
                    PREMIUM
                  </span>
                )}
              </div>
              <button
                onClick={handleSync}
                disabled={syncing}
                className="relative group w-12 h-12 rounded-full border border-arena-glow/20 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_15px_rgba(77,171,245,0.2)] overflow-hidden"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-arena-glow/20 to-rink-blue/20 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                <svg 
                  className={`w-6 h-6 text-ice-blue absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${syncing ? 'animate-spin' : 'group-hover:text-arena-glow'} transition-colors duration-300`}
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" 
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-col gap-2 text-ice-blue/80 font-game text-sm mt-2">
              <div className="flex gap-4">
                <span>ID: {user?.slapshotId}</span>
                <span>•</span>
                <span>
                  <a 
                    href={getSteamProfileUrl(user?.steamId)} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="hover:text-arena-glow transition-colors duration-300"
                  >
                    STEAM: {user?.steamId}
                  </a>
                </span>
              </div>
              <div className="flex gap-4">
                <span>Created: {formatDate(user?.createdAt)}</span>
                <span>•</span>
                <span>Last Active: {formatDate(user?.lastTrackedAt)}</span>
              </div>
              {syncError && (
                <div className="text-goal-red mt-2 flex items-center gap-2">
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  {syncError}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <StatCard title="OVERALL STATS">
          <StatItem label="GAMES PLAYED" value={user?.totalGames || '0'} color="text-ice-blue" />
          <StatItem label="WINS" value={user?.totalWins || '0'} color="text-arena-glow" />
          <StatItem label="LOSSES" value={user?.totalLosses || '0'} color="text-goal-red" />
          <StatItem label="WIN RATE" value={`${user?.winRate || '0'}%`} color="text-arena-glow" />
        </StatCard>

        <StatCard title="HOME LOADOUT">
          {user?.outfits?.loadout_home && Object.entries(user.outfits.loadout_home).map(([key, value]) => (
            <LoadoutItem
              key={key}
              name={formatItemName(key)}
              value={value}
              isColor={isColorItem(key)}
            />
          ))}
        </StatCard>

        <StatCard title="AWAY LOADOUT">
          {user?.outfits?.loadout_away && Object.entries(user.outfits.loadout_away).map(([key, value]) => (
            <LoadoutItem
              key={key}
              name={formatItemName(key)}
              value={value}
              isColor={isColorItem(key)}
            />
          ))}
        </StatCard>
      </div>

      {/* Match History */}
      <StatCard title="MATCH HISTORY" className="achievement-item">
        {loading ? (
          <div className="flex items-center justify-center py-8">
            <div className="flex flex-col items-center gap-4">
              <div className="w-12 h-12 border-4 border-arena-glow/20 border-t-arena-glow rounded-full animate-spin"></div>
              <div className="text-ice-blue font-game animate-pulse">Loading matches...</div>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            {matches.map((match, index) => (
              <div key={match.game.gameId} style={{ animationDelay: `${index * 50}ms` }}>
                <MatchCard match={match} />
              </div>
            ))}

            {hasMore && (
              <button
                onClick={loadMoreMatches}
                className="w-full mt-6 py-3 bg-deep-navy/95 text-ice-blue rounded-lg border border-arena-glow/20 hover:border-arena-glow/40 transition-all duration-300 font-game hover:shadow-[0_0_15px_rgba(77,171,245,0.2)] relative group overflow-hidden"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-arena-glow/20 to-rink-blue/20 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                <span className="relative flex items-center justify-center gap-2">
                  LOAD MORE MATCHES
                  <svg className="w-4 h-4 transform group-hover:translate-y-1 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                  </svg>
                </span>
              </button>
            )}
          </div>
        )}
      </StatCard>
    </div>
  );
}
