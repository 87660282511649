import React, { useState } from 'react';
import { searchPlayer } from '../services/api';

export default function PlayerSearchDropdown({ onSelect, placeholder = "Search for a player..." }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchQuery.trim()) {
      setError('Please enter a search term');
      return;
    }

    setError('');
    setIsLoading(true);
    try {
      const result = await searchPlayer(searchQuery.trim());
      if (result.success && result.data) {
        // Handle both single player and array of players
        setSearchResults(Array.isArray(result.data) ? result.data : [result.data]);
        setShowResults(true);
      } else {
        setError(result.error || 'Player not found');
        setSearchResults([]);
      }
    } catch (err) {
      setError(err.message || 'Search failed. Please try again.');
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = (player) => {
    onSelect(player);
    setSearchQuery('');
    setSearchResults([]);
    setShowResults(false);
  };

  return (
    <div className="relative">
      <form onSubmit={handleSearch} className="relative">
        <input
          type="text"
          className={`w-full bg-deep-navy/95 border ${error ? 'border-goal-red' : 'border-arena-glow/20'} text-ice-blue px-6 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-arena-glow/30 transition-all duration-300 hover:border-arena-glow/40 font-game placeholder:font-normal`}
          placeholder={placeholder}
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setError('');
          }}
          disabled={isLoading}
        />
        
        <button
          type="submit"
          disabled={isLoading}
          className="absolute right-2 top-1/2 -translate-y-1/2 bg-rink-blue/90 text-white px-6 py-2 rounded-md hover:bg-rink-blue transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2 font-game border border-arena-glow/20 hover:border-arena-glow/40 hover:shadow-text-glow"
        >
          {isLoading ? (
            <>
              <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              SEARCHING...
            </>
          ) : (
            <>
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
              SEARCH
            </>
          )}
        </button>
      </form>

      {error && (
        <div className="absolute -bottom-6 left-0 text-goal-red text-sm font-game">
          {error}
        </div>
      )}

      {showResults && searchResults.length > 0 && (
        <div className="absolute z-10 w-full mt-2 bg-deep-navy/95 border border-arena-glow/20 rounded-lg shadow-lg max-h-60 overflow-y-auto">
          {searchResults.map((player) => (
            <button
              key={player.slapshotId}
              onClick={() => handleSelect(player)}
              className="w-full flex items-center gap-3 p-3 hover:bg-rink-blue/20 transition-all duration-300 group border-b border-arena-glow/10 last:border-b-0"
            >
              {player.avatarUrl && (
                <img 
                  src={player.avatarUrl} 
                  alt={player.username} 
                  className="w-8 h-8 rounded-full border border-arena-glow/20"
                />
              )}
              <span className="text-ice-blue font-game group-hover:text-arena-glow transition-colors duration-300">
                {player.username}
              </span>
              {player.isPremium && (
                <span className="text-[10px] px-1.5 py-0.5 bg-goal-gold/20 text-goal-gold rounded-full border border-goal-gold/20">
                  PRO
                </span>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
