import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials, logout } from '../store/authSlice';
import api from '../services/api';

export const useAuth = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user, token } = useSelector((state) => state.auth);
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        
        if (storedToken && !user) {
          try {
            const response = await api.get('/auth/verify');
            dispatch(setCredentials({
              user: response.data.user,
              token: storedToken
            }));
          } catch (error) {
            console.error('Token verification failed:', error);
            dispatch(logout());
          }
        }
      } finally {
        setIsInitializing(false);
      }
    };

    initializeAuth();
  }, [dispatch, user]);

  const handleSteamLogin = () => {
    window.location.href = 'http://localhost:3000/auth/steam';
  };

  return {
    isAuthenticated,
    user,
    token,
    isInitializing,
    handleSteamLogin,
    logout: () => dispatch(logout())
  };
};

export default useAuth;
