import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getMatchDetails } from '../services/api';

const PlayerCard = ({ player, team }) => (
  <Link
    to={`/player/${player.player.slapshotId}`}
    className="flex items-center justify-between p-4 bg-rink-blue/5 rounded-lg transition-all duration-300 group transform hover:scale-[1.02] hover:shadow-[0_0_15px_rgba(77,171,245,0.1)]"
  >
    <div className="flex items-center gap-4">
      <div className="relative group">
        <div className="absolute -inset-0.5 bg-gradient-to-r from-arena-glow/50 to-rink-blue/50 rounded-full blur opacity-25 group-hover:opacity-50 transition duration-300"></div>
        <div className="relative w-12 h-12 bg-rink-blue/20 rounded-full flex items-center justify-center border border-arena-glow/20 group-hover:border-arena-glow/40 transition-all duration-300">
          <span className="text-ice-blue text-lg font-game group-hover:text-arena-glow transition-colors duration-300">
            {player.player.username[0].toUpperCase()}
          </span>
        </div>
      </div>
      <div>
        <p className="text-ice-blue font-game text-lg group-hover:text-arena-glow transition-colors duration-300">
          {player.player.username}
        </p>
        <div className="flex items-center gap-2">
          <span className="text-ice-blue/60 text-sm font-game">SCORE: {player.score}</span>
          {player.gameWinningGoals > 0 && (
            <span className="text-xs px-2 py-0.5 bg-goal-gold/20 text-goal-gold rounded-full border border-goal-gold/20 animate-pulse">
              GWG
            </span>
          )}
        </div>
      </div>
    </div>
    <div className="text-right">
      <div className="text-goal-red font-game text-xl drop-shadow-[0_0_3px_rgba(255,71,87,0.3)] group-hover:scale-110 transition-transform duration-300">
        {player.goals || 0} GOALS
      </div>
      <div className="text-sm space-y-1">
        <p className="text-arena-glow font-game">{player.assists || 0} ASSISTS</p>
        <p className="text-ice-blue/60 font-game">{player.saves || 0} SAVES</p>
      </div>
    </div>
  </Link>
);

const DetailCard = ({ label, value }) => (
  <div className="p-4 bg-rink-blue/5 rounded-lg transition-all duration-300 group transform hover:scale-[1.02] hover:shadow-[0_0_15px_rgba(77,171,245,0.1)]">
    <p className="text-ice-blue/60 text-sm font-game group-hover:text-ice-blue transition-colors duration-300">{label}</p>
    <p className="text-ice-blue font-game text-lg drop-shadow-[0_0_3px_rgba(77,171,245,0.3)] group-hover:text-arena-glow transition-colors duration-300">
      {value}
    </p>
  </div>
);

const SortableHeader = ({ label, field, sortConfig, onSort }) => (
  <th 
    onClick={() => onSort(field)}
    className="p-4 text-ice-blue/60 font-game cursor-pointer hover:text-arena-glow transition-colors duration-200 whitespace-nowrap group"
  >
    <div className="flex items-center gap-2">
      <span className="group-hover:scale-105 transition-transform duration-300">{label}</span>
      <span className={`text-arena-glow/50 transition-transform duration-300 ${
        sortConfig.key === field ? 'scale-110' : ''
      }`}>
        {sortConfig.key === field ? (sortConfig.direction === 'asc' ? '↑' : '↓') : '⇅'}
      </span>
    </div>
  </th>
);

export default function MatchDetails() {
  const { id } = useParams();
  const [match, setMatch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'score', direction: 'desc' });

  useEffect(() => {
    const fetchMatch = async () => {
      try {
        const data = await getMatchDetails(id);
        setMatch(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMatch();
  }, [id]);

  const requestSort = (key) => {
    let direction = 'desc';
    if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = 'asc';
    }
    setSortConfig({ key, direction });
  };

  const getSortedPlayerGames = () => {
    if (!match) return [];
    const sortedGames = [...match.playerGames];
    return sortedGames.sort((a, b) => {
      const aValue = a[sortConfig.key] || 0;
      const bValue = b[sortConfig.key] || 0;
      if (aValue === bValue) {
        return b.score - a.score;
      }
      if (sortConfig.direction === 'asc') {
        return aValue > bValue ? 1 : -1;
      }
      return aValue < bValue ? 1 : -1;
    });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center gap-4">
          <div className="w-16 h-16 border-4 border-arena-glow/20 border-t-arena-glow rounded-full animate-spin"></div>
          <div className="text-ice-blue font-game animate-pulse">Loading match details...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-goal-red/10 border border-goal-red rounded-lg p-6 text-goal-red font-game flex items-center gap-3">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          {error}
        </div>
      </div>
    );
  }

  if (!match) return null;

  const getTeamPlayers = (team) => {
    return match.playerGames
      .filter(pg => pg.team === team)
      .sort((a, b) => b.score - a.score);
  };

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Match Header */}
      <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-8 mb-8 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.1)]">
        <div className="text-center space-y-6">
          <h1 className="font-game text-4xl text-ice-blue drop-shadow-[0_0_5px_rgba(77,171,245,0.4)] animate-subtle-pulse">
            MATCH #{match.gameId}
          </h1>
          <div className="flex justify-center items-center gap-12">
            <div className="text-6xl font-game text-goal-red drop-shadow-[0_0_5px_rgba(255,71,87,0.4)] animate-subtle-pulse transform hover:scale-110 transition-transform duration-300">
              {match.team1Score}
            </div>
            <div className="text-2xl font-game text-ice-blue/60">VS</div>
            <div className="text-6xl font-game text-goal-red drop-shadow-[0_0_5px_rgba(255,71,87,0.4)] animate-subtle-pulse transform hover:scale-110 transition-transform duration-300">
              {match.team2Score}
            </div>
          </div>
          <div className="flex items-center justify-center gap-4 text-ice-blue/80 font-game text-sm">
            <span className="px-3 py-1 bg-rink-blue/10 rounded-full border border-arena-glow/20">
              {match.gameMode}
            </span>
            <span className="px-3 py-1 bg-rink-blue/10 rounded-full border border-arena-glow/20">
              {match.arena}
            </span>
            <span className="px-3 py-1 bg-rink-blue/10 rounded-full border border-arena-glow/20">
              {new Date(match.playedAt).toLocaleString()}
            </span>
          </div>
          {match.endReason === "Overtime" && (
            <div className="inline-block mt-2 text-arena-glow font-game text-sm px-4 py-2 bg-arena-glow/10 rounded-full border border-arena-glow/20 winner-badge">
              OVERTIME WINNER
            </div>
          )}
        </div>
      </div>

      {/* Teams */}
      <div className="grid md:grid-cols-2 gap-8 mb-8">
        {/* Home Team */}
        <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-6 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.1)]">
          <div className="flex items-center justify-between mb-6">
            <h2 className="font-game text-2xl text-ice-blue drop-shadow-[0_0_3px_rgba(77,171,245,0.3)]">
              HOME TEAM
            </h2>
            {match.winner === 'home' && (
              <span className="text-sm px-3 py-1 bg-goal-gold/20 text-goal-gold rounded-full border border-goal-gold/20 animate-pulse">
                WINNER
              </span>
            )}
          </div>
          <div className="space-y-4">
            {getTeamPlayers('home').map((pg, index) => (
              <div key={pg.id} style={{ animationDelay: `${index * 100}ms` }} className="achievement-item">
                <PlayerCard player={pg} team="home" />
              </div>
            ))}
          </div>
        </div>

        {/* Away Team */}
        <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-6 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.1)]">
          <div className="flex items-center justify-between mb-6">
            <h2 className="font-game text-2xl text-ice-blue drop-shadow-[0_0_3px_rgba(77,171,245,0.3)]">
              AWAY TEAM
            </h2>
            {match.winner === 'away' && (
              <span className="text-sm px-3 py-1 bg-goal-gold/20 text-goal-gold rounded-full border border-goal-gold/20 animate-pulse">
                WINNER
              </span>
            )}
          </div>
          <div className="space-y-4">
            {getTeamPlayers('away').map((pg, index) => (
              <div key={pg.id} style={{ animationDelay: `${index * 100}ms` }} className="achievement-item">
                <PlayerCard player={pg} team="away" />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Match Details */}
      <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-6 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.1)] mb-8">
        <h2 className="font-game text-2xl text-ice-blue mb-6 drop-shadow-[0_0_3px_rgba(77,171,245,0.3)]">
          MATCH DETAILS
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <DetailCard label="DURATION" value={formatDuration(match.duration)} />
          <DetailCard label="REGION" value={match.region.toUpperCase()} />
          <DetailCard label="GAME MODE" value={match.gameMode.toUpperCase()} />
          <DetailCard label="END REASON" value={match.endReason.toUpperCase()} />
          <DetailCard label="ARENA" value={match.arena} />
          <DetailCard label="MATCH TYPE" value={match.matchType.toUpperCase()} />
          <DetailCard label="PERIODS" value={match.periodsEnabled ? "ENABLED" : "DISABLED"} />
          <DetailCard label="MERCY RULE" value={match.customMercyRule || "NONE"} />
        </div>
      </div>

      {/* Player Statistics */}
      <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-6 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.1)] mb-8">
        <h2 className="font-game text-2xl text-ice-blue mb-6 drop-shadow-[0_0_3px_rgba(77,171,245,0.3)]">
          PLAYER STATISTICS
        </h2>
        <div className="overflow-x-auto scrollbar-thin scrollbar-thumb-arena-glow/20 scrollbar-track-deep-navy">
          <div className="min-w-[1200px] lg:w-full">
            <table className="w-full text-left stats-table">
              <thead>
                <tr className="border-b border-arena-glow/20">
                  <th className="sticky left-0 z-10 bg-deep-navy/95 p-4 text-ice-blue/60 font-game">PLAYER</th>
                  <th className="sticky left-[140px] z-10 bg-deep-navy/95 p-4 text-ice-blue/60 font-game">TEAM</th>
                  <SortableHeader label="SCORE" field="score" sortConfig={sortConfig} onSort={requestSort} />
                  <SortableHeader label="GOALS" field="goals" sortConfig={sortConfig} onSort={requestSort} />
                  <SortableHeader label="ASSISTS" field="assists" sortConfig={sortConfig} onSort={requestSort} />
                  <SortableHeader label="SAVES" field="saves" sortConfig={sortConfig} onSort={requestSort} />
                  <SortableHeader label="SHOTS" field="shots" sortConfig={sortConfig} onSort={requestSort} />
                  <SortableHeader label="BLOCKS" field="blocks" sortConfig={sortConfig} onSort={requestSort} />
                  <SortableHeader label="PASSES" field="passes" sortConfig={sortConfig} onSort={requestSort} />
                  <SortableHeader label="TAKEAWAYS" field="takeaways" sortConfig={sortConfig} onSort={requestSort} />
                  <SortableHeader label="TURNOVERS" field="turnovers" sortConfig={sortConfig} onSort={requestSort} />
                  <SortableHeader label="POSSESSION" field="possessionTimeSec" sortConfig={sortConfig} onSort={requestSort} />
                </tr>
              </thead>
              <tbody>
                {getSortedPlayerGames().map((pg, index) => (
                  <tr 
                    key={pg.id} 
                    className={`border-b border-arena-glow/10 transition-all duration-300
                      ${pg.team === 'home' ? 'bg-rink-blue/5' : 'bg-goal-red/5'}
                      hover:bg-arena-glow/10 hover:scale-[1.01]`}
                    style={{ animationDelay: `${index * 50}ms` }}
                  >
                    <td className="sticky left-0 z-10 bg-inherit p-4 text-ice-blue font-game whitespace-nowrap">
                      <Link to={`/player/${pg.player.slapshotId}`} className="hover:text-arena-glow transition-colors duration-300">
                        {pg.player.username}
                      </Link>
                    </td>
                    <td className="sticky left-[140px] z-10 bg-inherit p-4 text-ice-blue font-game uppercase">
                      {pg.team}
                    </td>
                    <td className="p-4 text-ice-blue font-game">{pg.score}</td>
                    <td className="p-4 text-ice-blue font-game">{pg.goals || 0}</td>
                    <td className="p-4 text-ice-blue font-game">{pg.assists || 0}</td>
                    <td className="p-4 text-ice-blue font-game">{pg.saves || 0}</td>
                    <td className="p-4 text-ice-blue font-game">{pg.shots || 0}</td>
                    <td className="p-4 text-ice-blue font-game">{pg.blocks || 0}</td>
                    <td className="p-4 text-ice-blue font-game">{pg.passes || 0}</td>
                    <td className="p-4 text-ice-blue font-game">{pg.takeaways || 0}</td>
                    <td className="p-4 text-ice-blue font-game">{pg.turnovers || 0}</td>
                    <td className="p-4 text-ice-blue font-game">{formatDuration(pg.possessionTimeSec || 0)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Special Achievements */}
      <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-6 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.1)]">
        <h2 className="font-game text-2xl text-ice-blue mb-6 drop-shadow-[0_0_3px_rgba(77,171,245,0.3)]">
          SPECIAL ACHIEVEMENTS
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {match.playerGames.map((pg, playerIndex) => {
            const achievements = [];
            if (pg.gameWinningGoals > 0) achievements.push("Game Winning Goal");
            if (pg.overtimeGoals > 0) achievements.push("Overtime Goal");
            if (pg.shutout) achievements.push("Shutout");
            if (pg.overtimeWin) achievements.push("Overtime Win");
            if (pg.postHits > 0) achievements.push(`${pg.postHits} Post Hit${pg.postHits > 1 ? 's' : ''}`);
            
            if (achievements.length === 0) return null;

            return (
              <div 
                key={pg.id} 
                className={`p-4 rounded-lg transition-all duration-300 transform hover:scale-[1.02] hover:shadow-[0_0_15px_rgba(77,171,245,0.1)]
                  ${pg.team === 'home' ? 'bg-rink-blue/5' : 'bg-goal-red/5'}`}
                style={{ animationDelay: `${playerIndex * 100}ms` }}
              >
                <div className="flex items-center gap-2 mb-3">
                  <div className="w-8 h-8 bg-rink-blue/20 rounded-full flex items-center justify-center border border-arena-glow/20">
                    <span className="text-ice-blue text-sm font-game">{pg.player.username[0].toUpperCase()}</span>
                  </div>
                  <p className="text-ice-blue font-game">{pg.player.username}</p>
                </div>
                <div className="space-y-2">
                  {achievements.map((achievement, index) => (
                    <div 
                      key={index} 
                      className="achievement-item flex items-center gap-2 text-arena-glow text-sm font-game bg-arena-glow/5 px-3 py-1.5 rounded-md border border-arena-glow/20" 
                      style={{ animationDelay: `${(playerIndex * 100) + (index * 100)}ms` }}
                    >
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                      </svg>
                      {achievement}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
