import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SearchDropdown from './SearchDropdown';
import api from '../services/api';

const Home = ({ onSearch, searchError }) => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [recentGames, setRecentGames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [globalStats, setGlobalStats] = useState(null);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isAuthenticated && user?.slapshotId) {
      fetchRecentGames();
    }
    if (!isAuthenticated) {
      fetchGlobalStats();
    }
  }, [isAuthenticated, user?.slapshotId]);

  const fetchGlobalStats = async () => {
    try {
      const response = await api.get('/api/stats/global');
      setGlobalStats(response.data);
    } catch (error) {
      console.error('Failed to fetch global stats:', error);
    }
  };

  const fetchRecentGames = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/api/players/${user.slapshotId}/games?limit=5`);
      setRecentGames(response.data.games);
    } catch (error) {
      console.error('Failed to fetch recent games:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatTimeDifference = (date) => {
    const now = new Date();
    const gameDate = new Date(date);
    const diffInHours = Math.floor((now - gameDate) / (1000 * 60 * 60));
    
    if (diffInHours < 1) {
      return 'Just now';
    } else if (diffInHours < 24) {
      return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
    } else {
      const diffInDays = Math.floor(diffInHours / 24);
      return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    }
  };

  const getGameScore = (playerGame) => {
    const game = playerGame.game;
    const isHome = playerGame.team === 'home';
    return isHome 
      ? `${game.team1Score} - ${game.team2Score}`
      : `${game.team2Score} - ${game.team1Score}`;
  };

  const handleMatchClick = (gameId) => {
    navigate(`/match/${gameId}`);
  };

  return (
    <>
      {/* Hero Section with Parallax */}
      <div className="relative min-h-[500px] overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center bg-no-repeat transition-transform duration-300"
          style={{
            backgroundImage: `url('https://playtracker.net/cdn-cgi/image/format=auto/https://playtracker-apollo.fra1.cdn.digitaloceanspaces.com/covers/api/game_cover_65952.jpg')`,
            transform: `translateY(${scrollY * 0.5}px)`,
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/60 to-deep-navy"></div>
        </div>
        <div className="absolute inset-0 ice-pattern opacity-20"></div>
        <div 
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32 relative"
          style={{ transform: `translateY(${scrollY * 0.2}px)` }}
        >
          <div className="text-center space-y-8">
            <h2 className="font-game text-5xl md:text-6xl font-bold text-ice-blue mb-8 animate-subtle-pulse drop-shadow-[0_0_15px_rgba(77,171,245,0.6)]">
              TRACK YOUR ICE TIME
            </h2>
            <p className="text-ice-blue/90 text-2xl mb-12 font-game drop-shadow-[0_0_8px_rgba(77,171,245,0.4)]">
              SEARCH PLAYERS AND MATCHES
            </p>

            <div className="max-w-2xl mx-auto transform hover:scale-105 transition-all duration-300">
              <div className="p-1 rounded-lg bg-gradient-to-r from-arena-glow via-ice-blue to-arena-glow">
                <div className="bg-deep-navy/95 rounded-lg p-1">
                  <SearchDropdown onSearch={onSearch} error={searchError} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Non-authenticated Sections */}
      {!isAuthenticated && (
        <>
          {/* Game Stats At A Glance */}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
            <h2 className="font-game text-4xl text-ice-blue text-center mb-12 drop-shadow-[0_0_8px_rgba(77,171,245,0.4)]">
              GAME STATS AT A GLANCE
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              <StatCard
                label="TOTAL MATCHES"
                value={globalStats?.totalGames || 0}
                icon="🏒"
              />
              <StatCard
                label="TOTAL GOALS"
                value={globalStats?.totalGoals || 0}
                icon="🥅"
              />
              <StatCard
                label="TOTAL PLAYERS"
                value={globalStats?.totalPlayers || 0}
                icon="👥"
              />
              <StatCard
                label="AVG GOALS/GAME"
                value={globalStats?.averageGoalsPerGame?.toFixed(1) || '0.0'}
                icon="📊"
              />
            </div>
          </div>

          {/* Why Track Your Stats */}
          <div className="bg-deep-navy/50 py-20">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 className="font-game text-4xl text-ice-blue text-center mb-12 drop-shadow-[0_0_8px_rgba(77,171,245,0.4)]">
                WHY TRACK YOUR STATS?
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                <FeatureCard
                  title="Match History"
                  description="View detailed match histories and performance trends"
                  icon="📈"
                />
                <FeatureCard
                  title="Player Insights"
                  description="Compare stats with other players and track your progress"
                  icon="🎯"
                />
                <FeatureCard
                  title="Real-time Updates"
                  description="Get live game tracking and instant stat updates"
                  icon="⚡"
                />
                <FeatureCard
                  title="Community Rankings"
                  description="See where you stand among other players"
                  icon="🏆"
                />
              </div>
            </div>
          </div>

          {/* How It Works */}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
            <h2 className="font-game text-4xl text-ice-blue text-center mb-12 drop-shadow-[0_0_8px_rgba(77,171,245,0.4)]">
              HOW IT WORKS
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              <StepCard
                number="1"
                title="Connect Your Account"
                icon="🔗"
              />
              <StepCard
                number="2"
                title="Play Matches"
                icon="🏒"
              />
              <StepCard
                number="3"
                title="Track Statistics"
                icon="📊"
              />
              <StepCard
                number="4"
                title="Analyze Performance"
                icon="📈"
              />
            </div>
          </div>

          {/* Featured Highlights */}
          <div className="bg-deep-navy/50 py-20">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 className="font-game text-4xl text-ice-blue text-center mb-12 drop-shadow-[0_0_8px_rgba(77,171,245,0.4)]">
                FEATURED HIGHLIGHTS
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <HighlightCard
                  title="Highest Scoring"
                  value={globalStats?.featuredHighlights?.highestScoring?.totalScore || 0}
                  label="GOALS"
                  icon="🥅"
                />
                <HighlightCard
                  title="Most Overtime Wins"
                  value={globalStats?.featuredHighlights?.mostOvertimeWins?.totalOvertimeWins || 0}
                  label="WINS"
                  icon="⚡"
                />
                <HighlightCard
                  title="Longest Win Streak"
                  value={globalStats?.featuredHighlights?.longestWinStreak?.totalWins || 0}
                  label="WINS"
                  icon="🔥"
                />
              </div>
            </div>
          </div>
        </>
      )}

      {/* Stats Section (Only shown when logged in) */}
      {isAuthenticated && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Quick Stats Card */}
            <div className="transform hover:-translate-y-2 transition-all duration-500">
              <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-6 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.2)] relative overflow-hidden group">
                <div className="absolute inset-0 bg-gradient-to-br from-arena-glow/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                <h3 className="font-game text-2xl text-ice-blue mb-8 drop-shadow-[0_0_3px_rgba(77,171,245,0.3)]">QUICK STATS</h3>
                <div className="space-y-4">
                  <StatItem label="GOALS" value={user?.totalGoals || 0} color="text-goal-red" />
                  <StatItem label="ASSISTS" value={user?.totalAssists || 0} color="text-arena-glow" />
                  <StatItem label="GAMES" value={user?.totalGames || 0} color="text-ice-blue" />
                  <StatItem label="WIN RATE" value={`${(user?.winRate || 0).toFixed(1)}%`} color="text-goal-gold" />
                </div>
              </div>
            </div>

            {/* Recent Matches */}
            <div className="md:col-span-2 transform hover:-translate-y-2 transition-all duration-500">
              <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-6 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.2)] relative overflow-hidden group">
                <div className="absolute inset-0 bg-gradient-to-br from-arena-glow/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                <h3 className="font-game text-2xl text-ice-blue mb-8 drop-shadow-[0_0_3px_rgba(77,171,245,0.3)]">RECENT MATCHES</h3>
                <div className="space-y-4">
                  {loading ? (
                    <div className="text-ice-blue/60 text-center py-4">Loading matches...</div>
                  ) : recentGames.length > 0 ? (
                    recentGames.map((playerGame, index) => (
                      <MatchItem 
                        key={playerGame.game.gameId}
                        matchId={playerGame.game.gameId.slice(0, 8)}
                        fullMatchId={playerGame.game.gameId}
                        time={formatTimeDifference(playerGame.game.playedAt)}
                        score={getGameScore(playerGame)}
                        result={playerGame.won ? 'VICTORY' : 'DEFEAT'}
                        isWinner={playerGame.won}
                        arena={playerGame.game.arena}
                        endReason={playerGame.game.endReason}
                        onClick={handleMatchClick}
                        style={{ animationDelay: `${index * 100}ms` }}
                      />
                    ))
                  ) : (
                    <div className="text-ice-blue/60 text-center py-4">No recent matches found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const StatCard = ({ label, value, icon }) => (
  <div className="transform hover:-translate-y-2 transition-all duration-500 h-[200px]">
    <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-6 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.2)] relative overflow-hidden group h-full flex flex-col justify-between">
      <div className="absolute inset-0 bg-gradient-to-br from-arena-glow/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
      <div className="text-4xl">{icon}</div>
      <div className="flex-grow flex flex-col justify-center">
        <div className="font-game text-3xl text-ice-blue">{value}</div>
        <div className="text-ice-blue/80 font-game text-sm mt-2">{label}</div>
      </div>
    </div>
  </div>
);

const FeatureCard = ({ title, description, icon }) => (
  <div className="transform hover:-translate-y-2 transition-all duration-500 h-[250px]">
    <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-6 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.2)] relative overflow-hidden group h-full flex flex-col">
      <div className="absolute inset-0 bg-gradient-to-br from-arena-glow/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
      <div className="text-4xl mb-4">{icon}</div>
      <div className="flex-grow flex flex-col justify-between">
        <h3 className="font-game text-xl text-ice-blue">{title}</h3>
        <p className="text-ice-blue/80 text-sm mt-4">{description}</p>
      </div>
    </div>
  </div>
);

const StepCard = ({ number, title, icon }) => (
  <div className="transform hover:-translate-y-2 transition-all duration-500 h-[200px]">
    <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-6 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.2)] relative overflow-hidden group h-full flex flex-col justify-between">
      <div className="absolute inset-0 bg-gradient-to-br from-arena-glow/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
      <div className="text-4xl">{icon}</div>
      <div className="flex-grow flex flex-col justify-center">
        <div className="font-game text-5xl text-arena-glow mb-2">{number}</div>
        <h3 className="font-game text-xl text-ice-blue">{title}</h3>
      </div>
    </div>
  </div>
);

const HighlightCard = ({ title, value, label, icon }) => (
  <div className="transform hover:-translate-y-2 transition-all duration-500 h-[200px]">
    <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-6 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.2)] relative overflow-hidden group h-full flex flex-col justify-between">
      <div className="absolute inset-0 bg-gradient-to-br from-arena-glow/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
      <div className="text-4xl">{icon}</div>
      <div className="flex-grow flex flex-col justify-center">
        <h3 className="font-game text-xl text-ice-blue mb-2">{title}</h3>
        <div className="font-game text-3xl text-goal-gold">{value}</div>
        <div className="text-ice-blue/80 font-game text-sm mt-2">{label}</div>
      </div>
    </div>
  </div>
);

const StatItem = ({ label, value, color }) => (
  <div className="group flex justify-between items-center bg-rink-blue/5 p-4 rounded-md hover:bg-rink-blue/10 transition-all duration-300 transform hover:scale-[1.02]">
    <span className="text-ice-blue/80 font-game text-sm group-hover:text-ice-blue transition-colors duration-300">{label}</span>
    <span className={`${color} font-game text-xl drop-shadow-[0_0_3px_rgba(77,171,245,0.3)] group-hover:scale-110 transition-transform duration-300`}>
      {value}
    </span>
  </div>
);

const MatchItem = ({ matchId, fullMatchId, time, score, result, isWinner, arena, endReason, onClick, style }) => (
  <div 
    onClick={() => onClick(fullMatchId)}
    className="achievement-item flex items-center justify-between p-4 bg-rink-blue/5 rounded-md hover:bg-rink-blue/10 transition-all duration-300 cursor-pointer transform hover:scale-[1.02] hover:shadow-[0_0_15px_rgba(77,171,245,0.1)]"
    style={style}
  >
    <div className="flex items-center gap-4">
      <div className="w-14 h-14 bg-rink-blue/20 rounded-full flex items-center justify-center border border-arena-glow/20 group-hover:border-arena-glow/40 transition-colors duration-300">
        <span className="text-ice-blue font-game text-sm drop-shadow-[0_0_2px_rgba(77,171,245,0.3)]">{matchId}</span>
      </div>
      <div>
        <p className="text-ice-blue font-game text-sm">{arena}</p>
        <div className="flex items-center gap-2">
          <p className="text-ice-blue/60 text-sm">{time}</p>
          {endReason === 'Overtime' && (
            <span className="text-xs px-2 py-0.5 bg-goal-gold/20 text-goal-gold rounded-full border border-goal-gold/20 animate-pulse">
              OT
            </span>
          )}
        </div>
      </div>
    </div>
    <div className="text-right">
      <p className="text-goal-red font-game text-xl drop-shadow-[0_0_3px_rgba(255,71,87,0.3)] score-pop">{score}</p>
      <p className={`font-game text-sm ${isWinner ? 'text-arena-glow winner-badge' : 'text-goal-red'}`}>{result}</p>
    </div>
  </div>
);

export default Home;
