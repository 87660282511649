import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../store/authSlice';
import { fetchMatchmakingStats } from '../store/matchmakingSlice';

const Navbar = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { inQueue, inMatch } = useSelector((state) => state.matchmaking);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMatchmakingStats());
    const interval = setInterval(() => {
      dispatch(fetchMatchmakingStats());
    }, 60000);
    return () => clearInterval(interval);
  }, [dispatch]);

  const handleSteamLogin = () => {
    window.location.href = 'http://localhost:3000/auth/steam';
  };

  const getUserInitial = () => {
    if (!user?.name) return '';
    return user.name.charAt(0).toUpperCase();
  };

  return (
    <>
      <nav className="bg-deep-navy/95 backdrop-blur-sm border-b border-arena-glow/20 sticky top-0 z-50 transition-all duration-300 hover:border-arena-glow/40 hover:shadow-[0_0_20px_rgba(77,171,245,0.1)]">
        <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12">
          <div className="flex items-center justify-between h-24">
            {/* Logo and Navigation Links */}
            <div className="flex items-center space-x-16">
              <Link 
                to="/" 
                className="font-game text-3xl font-bold text-ice-blue hover:text-arena-glow transition-all duration-300 flex items-center gap-4 group shrink-0"
              >
                <div className="relative">
                  <div className="absolute -inset-1 bg-gradient-to-r from-arena-glow to-rink-blue rounded-full blur opacity-25 group-hover:opacity-75 transition duration-300"></div>
                  <svg className="w-12 h-12 relative drop-shadow-[0_0_3px_rgba(77,171,245,0.4)] transform group-hover:scale-110 transition-transform duration-300" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
                    <path d="M12 6c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"/>
                  </svg>
                </div>
                <span className="hidden sm:inline drop-shadow-[0_0_5px_rgba(77,171,245,0.4)] animate-subtle-pulse">
                  SLAPSTATS
                </span>
              </Link>

              {/* Navigation Links */}
              <div className={`hidden md:flex ${isAuthenticated ? 'space-x-8 xl:space-x-12' : 'space-x-12'}`}>
                <NavLink to="/" icon="home">HOME</NavLink>
                <NavLink to="/leaderboard" icon="chart">LEADERBOARD</NavLink>
                <NavLink to="/compare" icon="compare">COMPARE</NavLink>
                <NavLink to="/stats" icon="stats">STATS</NavLink>
                {isAuthenticated && (
                  <div className="flex space-x-8 xl:space-x-12 border-l border-arena-glow/20 pl-8 xl:pl-12">
                    <NavLink to="/profile" icon="profile">PROFILE</NavLink>
                    <NavLink to="/import" icon="import">IMPORT</NavLink>
                  </div>
                )}
              </div>
            </div>

            {/* Auth Section */}
            <div className="flex items-center">
              {isAuthenticated ? (
                <div className="group relative">
                  <button className="flex items-center gap-3 px-4 py-2 rounded-lg hover:bg-rink-blue/10 transition-all duration-300">
                    <div className="w-10 h-10 rounded-full bg-rink-blue/20 flex items-center justify-center border border-arena-glow/20 group-hover:border-arena-glow/40 transition-all duration-300">
                      <span className="text-ice-blue text-base">{getUserInitial()}</span>
                    </div>
                    <svg className="w-5 h-5 text-ice-blue" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  
                  {/* Dropdown Menu */}
                  <div className="absolute right-0 mt-2 w-48 py-2 bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300">
                    <div className="px-4 py-2 text-ice-blue font-game text-sm border-b border-arena-glow/20">
                      {user?.name}
                    </div>
                    <button
                      onClick={() => dispatch(logout())}
                      className="w-full px-4 py-2 text-left text-goal-red hover:bg-goal-red/10 font-game text-sm flex items-center gap-2 transition-colors duration-300"
                    >
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                      </svg>
                      LOGOUT
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  onClick={handleSteamLogin}
                  className="relative group px-8 py-3 bg-gradient-to-r from-rink-blue/80 to-arena-glow/80 text-white rounded-lg transition-all duration-300 hover:shadow-[0_0_15px_rgba(77,171,245,0.3)] transform hover:scale-105"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-arena-glow/20 to-rink-blue/20 opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-lg"></div>
                  <span className="relative flex items-center gap-3 font-game text-sm">
                    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 22C6.5 22 2 17.5 2 12S6.5 2 12 2s10 4.5 10 10-4.5 10-10 10z"/>
                      <path d="M19.5 12c0 4.1-3.3 7.4-7.4 7.4-4.1 0-7.4-3.3-7.4-7.4 0-4.1 3.3-7.4 7.4-7.4 4.1 0 7.4 3.3 7.4 7.4z"/>
                    </svg>
                    <span className="hidden sm:inline">LOGIN WITH STEAM</span>
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </nav>

      {/* Floating Status Bar */}
      <div className="fixed bottom-8 left-1/2 transform -translate-x-1/2 z-50">
        <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-full px-10 py-4 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.1)] flex items-center gap-12 animate-float">
          <div className="flex items-center gap-4 text-ice-blue font-game text-sm group">
            <div className="relative">
              <div className="absolute -inset-1 bg-gradient-to-r from-arena-glow to-rink-blue rounded-full blur opacity-25 group-hover:opacity-75 transition duration-300"></div>
              <svg className="w-6 h-6 relative animate-spin-slow" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <div className="absolute -top-1 -right-1 w-2 h-2 bg-arena-glow rounded-full animate-pulse"></div>
            </div>
            <span className="drop-shadow-[0_0_2px_rgba(77,171,245,0.3)] group-hover:text-arena-glow transition-colors duration-300">
              {inQueue} IN QUEUE
            </span>
          </div>
          <div className="w-px h-6 bg-arena-glow/20"></div>
          <div className="flex items-center gap-4 text-ice-blue font-game text-sm group">
            <div className="relative">
              <div className="absolute -inset-1 bg-gradient-to-r from-arena-glow to-rink-blue rounded-full blur opacity-25 group-hover:opacity-75 transition duration-300"></div>
              <svg className="w-6 h-6 relative transform group-hover:scale-110 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
            </div>
            <span className="drop-shadow-[0_0_2px_rgba(77,171,245,0.3)] group-hover:text-arena-glow transition-colors duration-300">
              {inMatch} IN MATCH
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

// Helper component for navigation links
const NavLink = ({ to, icon, children }) => {
  const icons = {
    home: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
      </svg>
    ),
    chart: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
      </svg>
    ),
    compare: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
      </svg>
    ),
    stats: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
      </svg>
    ),
    profile: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
      </svg>
    ),
    import: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
      </svg>
    )
  };

  return (
    <Link 
      to={to} 
      className="relative group text-ice-blue hover:text-arena-glow transition-all duration-300 flex items-center gap-3 font-game text-sm"
    >
      <div className="absolute -inset-2 bg-gradient-to-r from-arena-glow/50 to-rink-blue/50 rounded-lg blur opacity-0 group-hover:opacity-25 transition duration-300"></div>
      <span className="relative transform group-hover:scale-110 transition-transform duration-300 drop-shadow-[0_0_2px_rgba(77,171,245,0.3)]">
        {icons[icon]}
      </span>
      <span className="relative group-hover:translate-x-0.5 transition-transform duration-300 drop-shadow-[0_0_2px_rgba(77,171,245,0.3)]">
        {children}
      </span>
    </Link>
  );
};

export default Navbar;
