import React, { useState } from 'react';
import { Menu, Transition } from '@headlessui/react';

const searchTypes = [
  {
    id: 'slapshot',
    name: 'SLAPSHOT ID',
    tooltip: 'Search using your unique Slapshot game ID',
    placeholder: 'Enter Slapshot ID...',
    icon: (
      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
      </svg>
    )
  },
  {
    id: 'steam',
    name: 'STEAM ID',
    tooltip: 'Search using your Steam account ID',
    placeholder: 'Enter Steam ID...',
    icon: (
      <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
        <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 22C6.5 22 2 17.5 2 12S6.5 2 12 2s10 4.5 10 10-4.5 10-10 10z"/>
        <path d="M19.5 12c0 4.1-3.3 7.4-7.4 7.4-4.1 0-7.4-3.3-7.4-7.4 0-4.1 3.3-7.4 7.4-7.4 4.1 0 7.4 3.3 7.4 7.4z"/>
      </svg>
    )
  },
  {
    id: 'match',
    name: 'MATCH ID',
    tooltip: 'Search for a specific match using Match ID',
    placeholder: 'Enter Match ID...',
    icon: (
      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
      </svg>
    )
  }
];

export default function SearchDropdown({ onSearch, error }) {
  const [selectedType, setSelectedType] = useState(searchTypes[0]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showTooltip, setShowTooltip] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [localError, setLocalError] = useState('');

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchQuery.trim()) {
      setLocalError('Please enter a search term');
      return;
    }

    setLocalError('');
    setIsLoading(true);
    try {
      await onSearch(selectedType.id, searchQuery.trim());
    } catch (err) {
      setLocalError(err.message || 'Search failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative max-w-2xl mx-auto">
      <form onSubmit={handleSearch} className="flex items-center gap-2">
        <Menu as="div" className="relative">
          {({ open }) => (
            <>
              <Menu.Button className="flex items-center gap-2 px-4 py-3 text-sm font-game text-ice-blue bg-deep-navy/95 border border-arena-glow/20 rounded-l-lg hover:border-arena-glow/40 hover:shadow-[0_0_15px_rgba(77,171,245,0.2)] focus:outline-none focus:ring-2 focus:ring-arena-glow/30 transition-all duration-300 transform hover:scale-[1.02]">
                {selectedType.icon}
                {selectedType.name}
                <svg className={`w-5 h-5 transition-transform duration-300 ${open ? 'rotate-180' : ''}`} viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </Menu.Button>

              <Transition
                show={open}
                enter="transition duration-200 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-150 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Menu.Items className="absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-deep-navy/95 border border-arena-glow/20 focus:outline-none z-10 backdrop-blur-sm">
                  <div className="py-1">
                    {searchTypes.map((type) => (
                      <Menu.Item key={type.id}>
                        {({ active }) => (
                          <button
                            className={`${
                              active ? 'bg-rink-blue/20 shadow-[0_0_15px_rgba(77,171,245,0.1)]' : ''
                            } flex items-center w-full px-4 py-2 text-sm font-game text-ice-blue hover:bg-rink-blue/20 transition-all duration-300 relative group`}
                            onClick={() => {
                              setSelectedType(type);
                              setLocalError('');
                            }}
                            onMouseEnter={() => setShowTooltip(type.id)}
                            onMouseLeave={() => setShowTooltip('')}
                          >
                            <span className={`flex items-center gap-2 transition-transform duration-300 ${active ? 'translate-x-1' : ''}`}>
                              {type.icon}
                              <span>{type.name}</span>
                            </span>
                            {showTooltip === type.id && (
                              <div className="absolute left-full ml-2 px-3 py-2 bg-deep-navy/95 border border-arena-glow/20 rounded-md text-xs whitespace-nowrap shadow-lg backdrop-blur-sm achievement-item">
                                {type.tooltip}
                              </div>
                            )}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>

        <div className="relative flex-1">
          <input
            type="text"
            className={`w-full bg-deep-navy/95 border ${error || localError ? 'border-goal-red shadow-[0_0_15px_rgba(255,71,87,0.2)]' : 'border-arena-glow/20'} text-ice-blue px-6 py-3 rounded-r-lg focus:outline-none focus:ring-2 focus:ring-arena-glow/30 transition-all duration-300 hover:border-arena-glow/40 hover:shadow-[0_0_15px_rgba(77,171,245,0.2)] font-game placeholder:font-normal transform hover:scale-[1.02]`}
            placeholder={selectedType.placeholder}
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setLocalError('');
            }}
            disabled={isLoading}
          />
          
          {(error || localError) && (
            <div className="absolute -bottom-6 left-0 text-goal-red text-sm font-game achievement-item">
              {error || localError}
            </div>
          )}

          <button
            type="submit"
            disabled={isLoading}
            className="absolute right-2 top-1/2 -translate-y-1/2 bg-gradient-to-r from-rink-blue via-arena-glow to-rink-blue text-white px-6 py-2 rounded-md transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2 font-game border border-arena-glow/20 hover:border-arena-glow/40 hover:shadow-[0_0_15px_rgba(77,171,245,0.3)] transform hover:scale-105 group"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-rink-blue via-arena-glow to-rink-blue opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-md"></div>
            <div className="relative flex items-center gap-2">
              {isLoading ? (
                <>
                  <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <span className="animate-pulse">SEARCHING...</span>
                </>
              ) : (
                <>
                  <svg className="w-4 h-4 transition-transform duration-300 group-hover:rotate-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                  SEARCH
                </>
              )}
            </div>
          </button>
        </div>
      </form>
    </div>
  );
}
