import axios from 'axios';
import { store } from '../store/store';
import { logout } from '../store/authSlice';

const api = axios.create({
  baseURL: 'http://localhost:3000',  // Remove /api since auth endpoints don't use it
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add token to requests if user is authenticated
api.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Handle response errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);

// Get current matchmaking stats
export const getCurrentMatchmakingStats = async () => {
  try {
    const response = await api.get('/api/stats/matchmaking/current');
    return response.data;
  } catch (error) {
    console.error('Get matchmaking stats error:', error);
    throw error;
  }
};

// Get historical matchmaking stats
export const getHistoricalMatchmakingStats = async (timeframe) => {
  try {
    const response = await api.get(`/api/stats/matchmaking/history/${timeframe}`);
    return response.data;
  } catch (error) {
    console.error('Get historical matchmaking stats error:', error);
    throw error;
  }
};

// Search by username (default) or Steam ID
export const searchPlayer = async (query, type = 'username') => {
  try {
    const response = await api.get(`/api/player/search`, {  // Add /api prefix for non-auth endpoints
      params: { type, query }
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Player search error:', error);
    if (error.response?.status === 404) {
      return { 
        success: false, 
        error: type === 'username' ? 'No players found with that username.' : 'Player not found. Please check if the Steam ID is correct.'
      };
    }
    return { 
      success: false, 
      error: 'An error occurred while searching for the player. Please try again later.' 
    };
  }
};

// Check player by Slapshot ID
export const checkPlayerExists = async (slapshotId) => {
  try {
    const response = await api.get(`/api/players/${slapshotId}`);  // Add /api prefix
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Slapshot ID check error:', error);
    if (error.response?.status === 404) {
      return { 
        success: false, 
        error: 'Player not found. Please check if the Slapshot ID is correct.' 
      };
    }
    return { 
      success: false, 
      error: 'An error occurred while checking the player. Please try again later.' 
    };
  }
};

// Check match by Match ID
export const checkMatchExists = async (matchId) => {
  try {
    const response = await api.get(`/api/games/${matchId}`);  // Add /api prefix
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Match ID check error:', error);
    if (error.response?.status === 404) {
      return { 
        success: false, 
        error: 'Game not found. Please check if the game ID is correct.' 
      };
    }
    return { 
      success: false, 
      error: 'An error occurred while checking the game. Please try again later.' 
    };
  }
};

// Get detailed match information
export const getMatchDetails = async (matchId) => {
  try {
    const response = await api.get(`/api/games/${matchId}`);  // Add /api prefix
    return response.data;
  } catch (error) {
    console.error('Get match details error:', error);
    throw error;
  }
};

// Get player statistics
export const getPlayerStats = async (slapshotId) => {
  try {
    const response = await api.get(`/api/players/${slapshotId}`);  // Add /api prefix
    return response.data;
  } catch (error) {
    console.error('Get player stats error:', error);
    throw error;
  }
};

// Get player's recent matches
export const getRecentMatches = async (slapshotId, page = 1) => {
  try {
    const response = await api.get(`/api/players/${slapshotId}/games`, {  // Add /api prefix
      params: { page }
    });
    return response.data;
  } catch (error) {
    console.error('Get recent matches error:', error);
    throw error;
  }
};

// Sync authenticated user's data
export const syncSelf = async () => {
  try {
    const response = await api.post('/api/players/self/sync');
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Self sync error:', error);
    if (error.response?.status === 429) {
      return {
        success: false,
        error: 'Please wait before refreshing again',
        nextUpdate: error.response.data.nextUpdate
      };
    }
    return {
      success: false,
      error: 'An error occurred while syncing. Please try again later.'
    };
  }
};

export default api;
