import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { searchPlayer, checkPlayerExists, checkMatchExists } from './services/api';
import useAuth from './hooks/useAuth';

// Components
import Navbar from './components/Navbar';
import Home from './components/Home';
import Profile from './components/Profile';
import PlayerProfile from './components/PlayerProfile';
import PlayerComparison from './components/PlayerComparison';
import MatchDetails from './components/MatchDetails';
import Leaderboard from './components/Leaderboard';
import AuthCallback from './components/AuthCallback';
import ProtectedRoute from './components/ProtectedRoute';
import ImportGames from './components/ImportGames';
import MatchmakingStats from './components/MatchmakingStats';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isInitializing } = useAuth();
  const [searchError, setSearchError] = useState(null);

  // Clear error when changing routes
  React.useEffect(() => {
    setSearchError(null);
  }, [location.pathname]);

  // Smooth scroll to top on route change
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [location.pathname]);

  const handleSearch = async (type, query) => {
    setSearchError(null);
    
    if (!query || query.trim() === '') {
      setSearchError('Please enter a valid search term');
      return;
    }

    try {
      let result;
      
      switch (type) {
        case 'match':
          result = await checkMatchExists(query);
          if (result.success) {
            navigate(`/match/${query}`);
          } else {
            setSearchError(result.error);
          }
          break;
          
        case 'steam':
          result = await searchPlayer(query);
          if (result.success && result.data && result.data.slapshotId) {
            navigate(`/player/${result.data.slapshotId}`);
          } else {
            setSearchError(result.error || 'Player not found');
          }
          break;
          
        case 'slapshot':
          result = await checkPlayerExists(query);
          if (result.success) {
            navigate(`/player/${query}`);
          } else {
            setSearchError(result.error);
          }
          break;
          
        default:
          setSearchError('Invalid search type');
      }
    } catch (error) {
      console.error('Search failed:', error);
      setSearchError('An unexpected error occurred. Please try again later.');
    }
  };

  // Show loading state while initializing
  if (isInitializing) {
    return (
      <div className="min-h-screen bg-deep-navy flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-arena-glow"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-deep-navy">
      <Navbar />

      <Routes>
        <Route 
          path="/" 
          element={<Home onSearch={handleSearch} searchError={searchError} />} 
        />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/player/:id" element={<PlayerProfile />} />
        <Route path="/compare" element={<PlayerComparison />} />
        <Route path="/match/:id" element={<MatchDetails />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/stats" element={<MatchmakingStats />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/import"
          element={
            <ProtectedRoute>
              <ImportGames />
            </ProtectedRoute>
          }
        />
        {/* Catch all route - redirect to home */}
        <Route path="*" element={<Home onSearch={handleSearch} searchError={searchError} />} />
      </Routes>
    </div>
  );
}

export default App;
