import React, { useState, useEffect } from 'react';
import { getHistoricalMatchmakingStats } from '../services/api';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const StatCard = ({ title, value, subtitle, trend }) => (
  <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-6 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_15px_rgba(77,171,245,0.2)] group">
    <h3 className="text-sm font-game text-ice-blue/70 mb-2">{title}</h3>
    <div className="flex items-baseline gap-2">
      <p className="text-3xl font-game text-arena-glow group-hover:scale-105 transition-transform duration-300 drop-shadow-[0_0_5px_rgba(77,171,245,0.4)]">
        {value}
      </p>
      {trend && (
        <span className={`text-sm ${trend > 0 ? 'text-green-400' : 'text-goal-red'}`}>
          {trend > 0 ? '↑' : '↓'} {Math.abs(trend)}%
        </span>
      )}
    </div>
    {subtitle && (
      <p className="text-xs text-ice-blue/50 mt-1">{subtitle}</p>
    )}
  </div>
);

const TimeframeButton = ({ active, children, onClick }) => (
  <button
    onClick={onClick}
    className={`relative group px-6 py-2.5 rounded-lg transition-all duration-300 font-game text-sm ${
      active
        ? 'bg-gradient-to-r from-rink-blue/80 to-arena-glow/80 text-ice-blue shadow-[0_0_15px_rgba(77,171,245,0.3)]'
        : 'bg-deep-navy/95 text-ice-blue/80 hover:text-ice-blue border border-arena-glow/20 hover:border-arena-glow/40 hover:shadow-[0_0_15px_rgba(77,171,245,0.2)]'
    }`}
  >
    <div className="relative flex items-center gap-2">
      {children}
      {active && (
        <div className="absolute inset-0 bg-gradient-to-r from-arena-glow/20 to-rink-blue/20 opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-lg"></div>
      )}
    </div>
  </button>
);

const MatchmakingStats = () => {
  const [timeframe, setTimeframe] = useState('hour');
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoading(true);
        const data = await getHistoricalMatchmakingStats(timeframe);
        setStats(data);
        setError(null);
      } catch (err) {
        setError('Failed to load matchmaking statistics');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
    const interval = setInterval(fetchStats, 60000);
    return () => clearInterval(interval);
  }, [timeframe]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    switch (timeframe) {
      case 'hour':
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      case 'day':
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      case 'week':
        return date.toLocaleDateString([], { weekday: 'short', month: 'short', day: 'numeric', hour: '2-digit' });
      case 'month':
        return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
      default:
        return date.toLocaleString();
    }
  };

  const chartData = stats?.history ? {
    labels: stats.history.map(point => formatTimestamp(point.timestamp)),
    datasets: [
      {
        label: 'In Queue',
        data: stats.history.map(point => point.inQueue),
        borderColor: 'rgba(77, 171, 245, 1)',
        backgroundColor: 'rgba(77, 171, 245, 0.1)',
        fill: true,
        tension: 0.4,
        pointRadius: 0,
        pointHoverRadius: 4,
        borderWidth: 2,
      },
      {
        label: 'In Match',
        data: stats.history.map(point => point.inMatch),
        borderColor: 'rgba(232, 244, 255, 1)',
        backgroundColor: 'rgba(232, 244, 255, 0.1)',
        fill: true,
        tension: 0.4,
        pointRadius: 0,
        pointHoverRadius: 4,
        borderWidth: 2,
      },
      {
        label: 'Total Players',
        data: stats.history.map(point => point.totalPlayers),
        borderColor: 'rgba(255, 71, 87, 1)',
        backgroundColor: 'rgba(255, 71, 87, 0.1)',
        fill: true,
        tension: 0.4,
        pointRadius: 0,
        pointHoverRadius: 4,
        borderWidth: 2,
      }
    ]
  } : null;

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#e8f4ff',
          font: {
            family: 'Katahdin',
            size: 14,
            weight: 'bold'
          },
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: 'rgba(10, 25, 48, 0.95)',
        titleColor: '#e8f4ff',
        bodyColor: '#e8f4ff',
        borderColor: 'rgba(77, 171, 245, 0.2)',
        borderWidth: 1,
        padding: 12,
        titleFont: {
          family: 'Katahdin',
          size: 14,
          weight: 'bold'
        },
        bodyFont: {
          family: 'Katahdin',
          size: 12
        },
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${context.parsed.y} players`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(77, 171, 245, 0.1)',
          drawBorder: false
        },
        ticks: {
          color: '#e8f4ff',
          font: {
            family: 'Katahdin',
            size: 12
          },
          maxRotation: 45,
          minRotation: 45,
          padding: 8
        }
      },
      y: {
        grid: {
          color: 'rgba(77, 171, 245, 0.1)',
          drawBorder: false
        },
        ticks: {
          color: '#e8f4ff',
          font: {
            family: 'Katahdin',
            size: 12
          },
          padding: 8,
          callback: function(value) {
            return value + ' players';
          }
        }
      }
    }
  };

  const getSampleInterval = () => {
    switch (timeframe) {
      case 'hour':
        return 'Every minute';
      case 'day':
        return 'Every 15 minutes';
      case 'week':
        return 'Every hour';
      case 'month':
        return 'Every 4 hours';
      default:
        return '';
    }
  };

  return (
    <div className="min-h-screen bg-deep-navy p-8">
      <div className="max-w-7xl mx-auto space-y-8">
        {/* Header */}
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
          <h1 className="text-4xl font-game text-ice-blue drop-shadow-[0_0_5px_rgba(77,171,245,0.4)] animate-subtle-pulse">
            MATCHMAKING STATISTICS
          </h1>
          <span className="text-sm font-game text-ice-blue/70 bg-deep-navy/50 px-4 py-2 rounded-lg border border-arena-glow/20">
            Sample Rate: {getSampleInterval()}
          </span>
        </div>

        {/* Timeframe Selection */}
        <div className="flex gap-4 overflow-x-auto pb-2 scrollbar-hide">
          {['hour', 'day', 'week', 'month'].map((t) => (
            <TimeframeButton
              key={t}
              active={timeframe === t}
              onClick={() => setTimeframe(t)}
            >
              {t.toUpperCase()}
            </TimeframeButton>
          ))}
        </div>

        {/* Stats Overview */}
        {stats && !loading && !error && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <StatCard
              title="PEAK PLAYERS"
              value={stats.peak.peak}
              subtitle={new Date(stats.peak.timestamp).toLocaleString()}
            />
            <StatCard
              title="AVERAGE IN QUEUE"
              value={Math.round(stats.average.avgQueue)}
              trend={((stats.average.avgQueue / stats.peak.peak) * 100).toFixed(1)}
            />
            <StatCard
              title="AVERAGE IN MATCH"
              value={Math.round(stats.average.avgMatch)}
              trend={((stats.average.avgMatch / stats.peak.peak) * 100).toFixed(1)}
            />
          </div>
        )}

        {/* Chart */}
        {loading ? (
          <div className="h-[500px] bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg flex items-center justify-center">
            <div className="flex flex-col items-center gap-4">
              <div className="w-12 h-12 border-4 border-arena-glow/20 border-t-arena-glow rounded-full animate-spin"></div>
              <div className="text-ice-blue font-game animate-pulse">Loading statistics...</div>
            </div>
          </div>
        ) : error ? (
          <div className="h-[500px] bg-deep-navy/95 backdrop-blur-sm border border-goal-red rounded-lg flex items-center justify-center">
            <div className="flex items-center gap-3 text-goal-red font-game">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              {error}
            </div>
          </div>
        ) : chartData ? (
          <div className="bg-deep-navy/95 backdrop-blur-sm border border-arena-glow/20 rounded-lg p-6 hover:border-arena-glow/40 transition-all duration-300 hover:shadow-[0_0_20px_rgba(77,171,245,0.1)]">
            <div className="h-[500px]">
              <Line data={chartData} options={chartOptions} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MatchmakingStats;
