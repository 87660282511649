import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../store/authSlice';
import api from '../services/api';

export default function AuthCallback() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleAuth = async () => {
      try {
        // Get token from URL
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        if (!token) {
          console.error('No token found in URL');
          navigate('/');
          return;
        }

        // First store the token so the interceptor can use it
        dispatch(setCredentials({
          user: null,
          token
        }));

        // Verify token and get user data
        const response = await api.get('/auth/verify');

        // Update store with user data
        dispatch(setCredentials({
          user: response.data.user,
          token
        }));

        // Clean up URL
        window.history.replaceState({}, document.title, '/');
        
        // Redirect to home
        navigate('/');
      } catch (error) {
        console.error('Auth callback error:', error);
        navigate('/');
      }
    };

    handleAuth();
  }, [dispatch, navigate, location]);

  return (
    <div className="min-h-screen bg-deep-navy flex items-center justify-center">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-arena-glow"></div>
    </div>
  );
}
